// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

#antibulli-app
{
  // Make the footer align to the bottom
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  position: relative;
  z-index: 111;

  margin: 0 auto;
  width: 100%;
  min-height: var(--app-height);
  overflow: hidden;

  // TODO: This is a quickfix...
  &.blog-0
  {
	#the-header .header-inner,
	#page-layout .the-editor-content
	{
	  visibility: hidden !important;
	}
  }
}

@keyframes antibulli19-reveal-animation {
  0% { visibility: visible; opacity: 0; }
  100% { visibility: visible; opacity: 1; }
}

#Coi-Renew
{
  bottom: config.$height_header_xs !important;
  margin-bottom: env(safe-area-inset-bottom, 0);
  @include config.mediaquery(sm)
  {
	bottom: env(safe-area-inset-bottom, 0) !important;
	margin-bottom: 0;
  }
}
body[data-template="page_frontpage_main"] #Coi-Renew
{
  bottom: env(safe-area-inset-bottom, 0) !important;
}

// Hotjar
body ._hj-widget-container ._hj-5vKq2__styles__surveyContainer
{
  @include config.mediaquery(xs2sm)
  {
	bottom: config.$height_header_xs !important;
	margin-bottom: env(safe-area-inset-bottom, 0);
  }
}
body ._hj-widget-container ._hj-5vKq2__styles__surveyContainer._hj-2Mo9X__styles__minimized._hj-fYmIf__styles__positionCenter
{
  background: none !important;
  box-shadow: none !important;
  ._hj-hRovs__styles__form
  {
	visibility: hidden !important;
	display: none !important;
  }
  ._hj-kWRoL__styles__openStateToggle
  {
	&:before
	{
	  display: none !important;
	}
  }
}