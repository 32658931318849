// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.acf-block
{
  &.acf-block-fullscreen
  {
	@include layout.make-fullscreen();
  }

  margin-top: 2em;
  margin-bottom: 2em;

  &:last-child
  {
	margin-bottom: 0;
  }
}