// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.page-layout
{
  align-self: flex-start;
  flex-grow: 1;
  flex-shrink: 0;

  position: relative;
  z-index: 222;

  margin: 0 auto;
  padding-bottom: env(safe-area-inset-bottom, 0);
  @include layout.container-style('large', true);
  &
  {
	width: 100%;
	min-height: var(--app-height);
  }

  .page-layout-inner
  {
	background: color.get-color(2);
	margin-top: env(safe-area-inset-top, 0);
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	height: 100%;
	min-height: var(--app-height);

	@include layout.set-padding-top-from-title_bar-heights();
	@include layout.set-padding-bottom-from-header-heights();
	@include config.mediaquery(sm)
	{
	  @include layout.set-padding-top-from-header-heights(); // The Header is positioned fixed at the bottom of the page...
	  & {
		padding-bottom: 0;
	  }
	}
  }

  .the-editor-content
  {
	width: 100%;
	@include config.mediaquery(sm)
	{
	  @include layout.set-margin-top-from-title_bar-heights(); // The Header is positioned fixed at the bottom of the page...
	}
  }
  &.no-title-bar:not(.page_frontpage_main)
  {
	.the-editor-content
	{
	  margin-top: 0 !important;
	}
  }

  //
  // Misc. page types
  &#page-layout-loading
  {
	background: color.get-color(2);
  }
  &.page_frontpage_main
  {
	.page-layout-inner
	{
	  padding-top: 0;
	}
	.the-editor-content
	{
	  @include config.mediaquery(sm)
	  {
		@include layout.set-margin-top-from-header-heights();
	  }
	}
  }

  //
  // Draw titlebar "fake"
  &:not(.is-home)
  {
	&:before
	{
	  //display: none !important;
	  // Draw background to avoid flickr on page transition
	  content: '';
	  display: block;
	  position: fixed;
	  top: 0;
	  left: 50%;
	  z-index: -1;
	  background: color.get-color(2);
	  @include layout.container-style('large', true);
	  &
	  {
		transform: translate3d(-50%, 0, 0);
		height: 100%;
		pointer-events: none;
	  }
	}

	&:after
	{
	  //display: none !important;
	  content: '';
	  display: block;
	  position: fixed;
	  @include layout.set-top-from-header-heights();
	  &
	  {
		left: 0;
		z-index: -1;
		background: color.get-color(0);
		width: 100vw;
	  }
	  @include layout.set-title_bar-heights();
	  & {
		pointer-events: none;
	  }
	  @include config.mediaquery(xs2sm)
	  {
		top: 0;
	  }
	}
  }
}

body[data-template="page_frontpage_sport"]
{
  .page-layout
  {
	.page-layout-inner
	{
	  background: color.get-color(1);

	  h1,h2,h3,h4,h5,h6,figcaption,.figcaption,cite,p
	  {
		color: color.get-color(2);
	  }
	  .wp-block-buttons .wp-block-button button:hover,
	  .wp-block-buttons .wp-block-button a:hover,
	  .wp-block-buttons .wp-block-button .wp-block-button__link:hover
	  {
		background: color.get-color(2) !important;
		color: color.get-color(1) !important;
	  }
	  .wp-block-table.is-style-stripes tbody tr:nth-child(odd)
	  {
		background: color.get-color(2);
		color: color.get-color(1);
	  }
	  .loading-animation
	  {
		.spinner
		{
		  border-color: rgba(255,255,255,.3);
		  border-left-color: color.get-color(2);
		}
	  }

	  .the-content,
	  .CookieDeclaration
	  {
		ul
		{
		  & > li
		  {
			&:before
			{
			  background: color.get-color(2);
			}
		  }
		}
		ul,
		p
		{
		  a
		  {
			color: color.get-color(2);
		  }
		}
	  }
	}
  }
}