// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

#the-header
{
  position: fixed;
  bottom: env(safe-area-inset-bottom, 0);
  left: 0;
  z-index: 999;
  border-top: .1rem solid color.get-color(6);
  background: color.get-color(2);
  width: 100%;
  @include layout.set-header-heights();

  @include config.mediaquery(sm)
  {
	bottom: auto;
	top: env(safe-area-inset-top, 0);
	border-top: none;
  }
  @include config.mediaquery(xs2sm)
  {
	// Cover safe area below the header
	&:before
	{
	  content: '';
	  display: block;
	  position: absolute;
	  top: 100%;
	  left: 0;
	  background: color.get-color(2);
	  width: 100%;
	  height: env(safe-area-inset-bottom, 0);
	  pointer-events: none;
	}
  }

  .header-inner
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;

	margin: 0 auto;
	padding-left: config.$inner_padding_xs * .5;
	padding-right: config.$inner_padding_xs * .5;
	height: 100%;
	animation: antibulli19-reveal-animation .3s ease-in-out 1 forwards;

	@include config.mediaquery(sm)
	{
	  padding-left: 0;
	  padding-right: 0;
	}
	@include config.mediaquery(md)
	{
	  justify-content: flex-end;
	  @include layout.container-style('large');
	}
  }

  .logo
  {
	position: relative;
	top: .3rem;
	justify-self: flex-start;
	margin-right: auto;
	margin-left: 1.5rem;
	@include config.mediaquery(xs2sm)
	{
	  display: none;
	}
  }
  .menu-overlay-toggler
  {
	margin-right: 1.5rem;
	@include config.mediaquery(xs2sm)
	{
	  display: none;
	}
  }
}