// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.play-button
{
  @include form.reset-default-button-style;
  @include form.no-appearance;
  &
  {
	position: absolute;
	inset: 0 0 0 0;
	z-index: 666;
  }

  .play-button-inner
  {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 444;
	transform: translate3d(-50%,-50%,0);
	transition: transform .4s ease-out;
	outline: none;
	background: url("#{config.$path_images}play-button.svg") no-repeat 50% 50%;
	background-size: cover;
	cursor: pointer;
	width: 6rem;
	height: 6rem;

	@include config.mediaquery(sm)
	{
	  width: 11rem;
	  height: 11rem;
	}
  }

  @include config.mediaquery(hover)
  {
	.play-button-inner
	{
	  transform: translate3d(-50%,-50%,0) scale(1.1);
	}
  }
}