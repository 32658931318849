// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.next-exit-active {
  z-index: 0;
  animation: leaveToLeft 300ms both ease-in-out;
  #title-bar
  {
	.title
	{
	  visibility: hidden;
	}
  }
  @include config.mediaquery(sm) {
	visibility: hidden;
	animation: leaveSmToLeft 300ms both .3s ease-out;
  }
}

.next-enter-active {
  z-index: 1;
  animation: enterFromRight 300ms both ease-in-out;
  @include config.mediaquery(sm) {
	animation: enterSmFromRight 300ms both ease-out;
  }
}

.prev-exit-active {
  z-index: 1;
  animation: leaveToRight 300ms both ease-in-out;

  #title-bar
  {
	.title
	{
	  visibility: hidden;
	}
  }
  @include config.mediaquery(sm) {
	visibility: hidden;
	animation: leaveSmToRight 300ms both .3s ease-out;
  }
}

.prev-enter-active {
  z-index: 0;
  animation: enterFromLeft 300ms both ease-in-out;
  @include config.mediaquery(sm) {
	animation: enterSmFromLeft 300ms both ease-out;
  }
}
.next-enter,
.prev-enter
{
  position: relative;
  z-index: 222;
}
.prev-enter
{
  z-index: 1 !important;
}
.next-exit,
.prev-exit
{
  @include config.mediaquery(xs2sm)
  {
	position: absolute !important;
	top: 0;
	z-index: 0 !important;
	&.prev-exit
	{
	  z-index: 2 !important;
	}
  }
}

@include config.mediaquery(xs2sm) {
  body.js-use-prev-transition
  {
	.next-exit-active {
	  z-index: 5 !important;
	  animation: leaveToRight 300ms both ease-in-out;
	}

	.next-enter-active {
	  z-index: 1;
	  animation: enterFromLeft 300ms both ease-in-out;
	}

	.prev-exit-active {
	  z-index: 5 !important;
	  animation: leaveToRight 300ms both ease-in-out;
	}

	.prev-enter-active {
	  z-index: 0;
	  animation: enterFromLeft 300ms both ease-in-out;
	}
  }
}


@keyframes leaveToLeft {
  from {
	transform: translate3d(0,0,0);
  }
  to {
	transform: translate3d(-35%,0,0);
  }
}
@keyframes enterFromLeft {
  from {
	transform: translate3d(-35%,0,0);
  }
  to {
	transform: translate3d(0,0,0);
  }
}
@keyframes leaveToRight {
  from {
	transform: translate3d(0,0,0);
  }
  to {
	transform: translate3d(100%,0,0);
  }
}
@keyframes enterFromRight {
  from {
	transform: translate3d(100%,0,0);
  }
  to {
	transform: translate3d(0,0,0);
  }
}


@keyframes leaveSmToLeft {
  from {
	opacity: 1;
  }
  to {
	opacity: 0;
  }
}
@keyframes enterSmFromRight {
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
}
@keyframes leaveSmToRight {
  from {
	opacity: 1;
  }
  to {
	opacity: 0;
  }
}
@keyframes enterSmFromLeft {
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
}