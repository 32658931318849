// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.loading-animation
{
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  pointer-events: none;
  visibility: hidden;

  .spinner
  {
	border: .8rem solid rgba(color.get-color(3, false), .2);
	border-left: .8rem solid color.get-color(3);

	position: absolute;
	top: 0;
	left: 50%;
	z-index: 111;
	display: block;
	border-radius: 50%;

	//will-change: transform;
	transform: translateZ(0);
	transform-origin: 50% 50%;

	margin: -6rem 0 0 -3rem;
	width: 6rem;
	height: 6rem;

	@include config.mediaquery(sm)
	{
	  border-width: 1rem;
	  margin: -8rem 0 0 -4rem;
	  width: 8rem;
	  height: 8rem;
	}

	&:after
	{
	  border-radius: 50%;
	}

	// Setup key frame animation
	@keyframes app-loading-animation {
	  0% { transform: rotate(0deg) translate3d(0,0,0); }
	  100% { transform: rotate(360deg) translate3d(0,0,0); }
	}
	& {
	  animation: app-loading-animation 1.2s infinite linear;
	}
  }

  .label
  {
	position: relative;
	z-index: 111;
	display: block;
	padding-top: .5em;
	color: color.get-color(1);
  }


  // Show the animation with a short delay
  @keyframes app-reveal-loading-animation
  {
	0% { transform: translate3d(-50%,1em,0); opacity: 0; visibility: visible; }
	100% { transform: translate3d(-50%,0,0); opacity: 1; visibility: visible; }
  }
  & {
	animation: app-reveal-loading-animation .4s 1 .1s ease-in-out forwards;
  }
}