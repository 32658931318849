// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.wp-block-image,
.wp-block-post-featured-image
{
  margin-top: 2em;
  margin-bottom: 2em;

  &.alignleft,
  &.aligncenter,
  &.alignright
  {
	display: flex !important;
	float: none;
	margin: inherit !important;
  }
  &:last-child
  {
	margin-bottom: 0;
  }

  img,
  svg
  {
	display: block;
	max-width: 100%;
	height: auto;
  }
  figcaption
  {
	@include layout.container-style('small', true);
	& {
	  margin: .5em auto 0;
	}
  }
}