// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

#menu-sport
{
  background: color.get-color(1);
  color: color.get-color(2);
  @include layout.set-inner-padding-sides(1.5,2,1.5,2);

  .name
  {
	@include config.h6;
	& {
	  margin-bottom: 1em;
	}
	@include typography.typography-1-bold();
	& {
	  text-transform: uppercase;
	}
  }

  .menu-sport-item
  {
	margin-bottom: 1em;
	@include typography.text-size(18,1em);
	@include typography.typography-1-medium();

	&:last-child
	{
	  margin-bottom: 0;
	}

	a
	{
	  display: flex;
	  flex-wrap: nowrap;
	  justify-content: flex-start;
	  align-items: center;

	  color: color.get-color(2);

	  text-decoration: none;

	  .icon
	  {
		position: relative;
		display: block;
		border: .1rem solid color.get-color(2);
		margin-right: .5em;
		width: 2.5rem;
		height: 2.5rem;
		overflow: hidden;

		img
		{
		  display: block;
		  position: absolute;
		  inset: 0 0 0 0;
		  width: 100%;
		  height: 100%;
		  object-fit: cover;
		}
	  }
	  .title
	  {
		position: relative;
		top: .1rem;
	  }
	}
  }

  //
  // Adjacent
  & + .menu-antibulli
  {
	@include layout.set-inner-padding-top(.5);
  }
}