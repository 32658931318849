// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.wp-block-buttons
{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 2em;
  margin-bottom: 2em;

  &:last-child
  {
	margin-bottom: 0;
  }

  &.aligncenter
  {
	justify-content: center;
  }
  &.alignright
  {
	justify-content: flex-end;
  }

  .wp-block-button
  {
	display: block;
	margin-right: 1.5em;

	&:last-child
	{
	  margin-right: 0;
	  margin-bottom: 0;
	}

	button,
	a,
	.wp-block-button__link
	{
	  @include form.default-button-style();

	  .label
	  {
		text-align: left;
	  }
	}

	&.is-style-outline
	{
	  button,
	  a,
	  .wp-block-button__link
	  {
		&:not(.has-background)
		{
		  background: transparent;
		}
		&:not(.has-text-color)
		{
		  border-color: color.get-color(1);
		  color: color.get-color(1);
		  svg
		  {
			path
			{
			  fill: color.get-color(1);
			}
		  }
		}
	  }
	}
	&:not(.is-style-outline)
	{
	  button,
	  a,
	  .wp-block-button__link
	  {
		border-color: transparent;
	  }
	}
  }
}