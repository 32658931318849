// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.menu-primary
{
  position: relative;
  top: .2rem;
  width: 100%;

  @include config.mediaquery(sm)
  {
	width: auto;
  }

  .menu
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	@include config.mediaquery(sm)
	{
	  justify-content: flex-start;
	}

	.menu-item
	{
	  @include typography.typography-1-bold();

	  @include config.mediaquery(md)
	  {
		margin-left: 1em;
	  }

	  &:first-child
	  {
		margin-left: 0;
	  }

	  a
	  {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;

		color: color.get-color(5);
		text-align: center;
		text-decoration: none;

		.icon
		{
		  display: block;
		  margin-bottom: .25em;
		  width: 2.2rem;
		  height: 2.2rem;

		  @include config.mediaquery(sm)
		  {
			width: 2.4rem;
			height: 2.4rem;
		  }

		  svg
		  {
			display: block;
			width: 100%;
			height: auto;
		  }
		}
		.title
		{
		  display: block;
		  width: 100%;
		  text-align: center;
		  white-space: nowrap;
		  @include config.tiny-font-size();

		  @include config.mediaquery(sm)
		  {
			@include config.small-font-size();
		  }
		}
	  }

	  &.js-active
	  {
		a
		{
		  color: color.get-color(3);
		}
		.icon
		{
		  svg
		  {
			path
			{
			  fill: color.get-color(3);
			}
		  }
		}
	  }
	}
  }
}