// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.blog-4,
.blogpath-fodbold
{
  .page-layout:after, // Titlebar "fake"
  #title-bar,
  #child-pages ul .child-page .index,
  .title-area .title-area-inner .index,
  #menu-blog
  {
	background: color.get-color(40);
  }

  #menu-overlay:before
  {
	background: color.get-color(41);
  }

  .loading-animation
  {
	.spinner
	{
	  border-color: rgba(color.get-color(40, false), .3);
	  border-left-color: color.get-color(40);
	}
  }
  .the-content ul,
  .the-content p
  {
	a
	{
	  color: color.get-color(40);
	}
  }

  #the-header .menu-primary .menu .menu-item
  {
	&.icon-football-goal
	{
	  a
	  {
		.icon
		{
		  svg
		  {
			position: relative;
			left: -20%;
			top: -15%;
			width: 140%;
		  }
		}
	  }
	}
  }

  #the-header
  {
	// Change the Antibulli logo
	.logo
	{
	  top: 0;
	  @include layout.aspect-ratio(540,130);
	  &
	  {
		background: url("#{config.$path_images}blogs/4/logo-md.png") no-repeat 50% 50%;
		background-size: cover;
		width: 27rem;
	  }

	  .name
	  {
		display: none;
	  }
	}
  }

  @include config.mediaquery(xs2sm)
  {
	#the-header
	{
	  background: color.get-color(41);
	  border-color: color.get-color(41);

	  .menu-primary .menu .menu-item
	  {
		a
		{
		  color: color.get-color(2);
		}

		.icon svg
		{
		  path
		  {
			fill: color.get-color(2);
		  }
		}

		&.js-active
		{
		  a
		  {
			color: color.get-color(1);
		  }

		  .icon svg
		  {
			path
			{
			  fill: color.get-color(1);
			}
		  }
		}
	  }
	}
  }
}

// Set white log on the title-bar (frontpage on ly)
@include config.mediaquery(xs2sm)
{
  body[data-template="page_frontpage_sport"][data-blog="4"],
  body[data-template="page_exercises"][data-blog="4"],
  body[data-template="page_activities"][data-blog="4"],
  body[data-template="page_coaching"][data-blog="4"]
  {
	#title-bar
	{
	  .title-bar-inner
	  {
		justify-content: flex-start;

		.title
		{
		  @include layout.aspect-ratio(360, 70);
		  &
		  {
			background: url("#{config.$path_images}blogs/4/logo-xs.png") no-repeat 50% 50%;
			background-size: cover;
			margin: 0;
			width: 18rem;

			opacity: 0;
			animation: antibulli-4-reveal-logo .2s ease-out .2s 1 forwards;
		  }

		  span
		  {
			display: none;
		  }
		}
	  }
	}
  }
}
@keyframes antibulli-4-reveal-logo {
  0% { opacity: 0; }
  100% { opacity: 1; }
}