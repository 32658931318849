// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

#menu-blog
{
  background: color.get-color(1);
  color: color.get-color(2);
  @include layout.set-inner-padding-sides(1.5,1.5,1.5,1.5);

  .name
  {
	@include config.h6;
	& {
	  margin-bottom: .75em;
	}
	@include typography.typography-1-bold();
	& {
	  text-transform: uppercase;
	}
  }

  .menu-item
  {
	margin-bottom: 1em;
	@include typography.text-size(18,1em);
	@include typography.typography-1-medium();

	&:last-child
	{
	  margin-bottom: 0;
	}

	a
	{
	  display: inline-flex;
	  flex-wrap: nowrap;
	  justify-content: flex-start;
	  align-items: center;

	  color: color.get-color(2);

	  text-decoration: none;

	  .title
	  {
		position: relative;
		top: .2rem;
	  }
	  &:before
	  {
		flex-grow: 0;
		flex-shrink: 0;
		justify-self: flex-end;

		content: '';
		display: block;
		background: url("#{config.$path_images}menu-item-arrow.svg") no-repeat 50% 50%;
		background-size: cover;
		margin-right: .5em;
		width: .8rem;
		height: 1.5rem;
	  }
	}

	&.js-active
	{
	  @include typography.typography-1-bold();
	  a
	  {
		text-decoration: underline;
		&:after
		{
		  display: none;
		}
	  }
	}
  }

  //
  // Adjacent
  & + .menu-sport
  {
	@include layout.set-inner-padding-top(1.75);
  }
}