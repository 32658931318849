// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.title-area
{
  //border-top: .1rem solid color.get-color(6);
  border-bottom: .1rem solid color.get-color(6);
  padding-top: 1em;
  padding-bottom: 1em;

  @include config.mediaquery(sm)
  {
	padding-top: 1.5em;
	padding-bottom: 1.5em;
  }

  .title-area-inner
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	@include layout.container-style('small');
	& {
	  margin: 0 auto;
	}

	.index
	{
	  flex-grow: 0;
	  flex-shrink: 0;

	  display: flex;
	  flex-wrap: nowrap;
	  justify-content: center;
	  align-items: center;

	  background: color.get-color(1);
	  border-radius: 50%;
	  color: color.get-color(2);
	  margin-right: .5em;
	  padding-top: .5rem;
	  width: 4.6rem;
	  height: 4.6rem;
	  @include typography.text-size(32, 1em);
	  @include typography.typography-1-bold();

	  @include config.mediaquery(sm)
	  {
		padding-top: .8rem;
		width: 6.5rem;
		height: 6.5rem;
		@include typography.text-size(48, 1em);
	  }

	  &.num-digits-2
	  {
		padding-right: .05em;
		@include typography.text-size(26, 1em);
		& {
		  letter-spacing: -.025em;
		}

		@include config.mediaquery(sm)
		{
		  padding-right: .125em;
		  @include typography.text-size(42, 1em);
		  & {
			letter-spacing: -.085em;
		  }
		}
	  }
	  &.num-digits-3
	  {
		@include typography.text-size(22, 1em);
		@include config.mediaquery(sm)
		{
		  @include typography.text-size(28, 1em);
		}
	  }
	}
	.title
	{
	  position: relative;
	  top: .5rem;
	  margin-bottom: 0;
	}
  }

  &.no-index
  {
	border-bottom: none;
	margin-bottom: -1.5em;
	padding-top: 1.5em;
	padding-bottom: 0;
	@include config.mediaquery(sm)
	{
	  padding-top: 2em;
	}
  }

  //
  // Adjacent
  & + .the-content
  {
	margin-top: -1em;
  }
  &.no-index + .the-content
  {
	margin-top: -.5em;
  }
}