// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.js-fullscreen-enabled
{
  .animation
  {
	&.state-ended.has-poster video,
	&.has-poster video,
	&.state-ended video,
	video
	{
	  visibility: visible !important;
	}
  }
}