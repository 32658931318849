// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

#child-pages
{
  background: color.get-color(2);
  width: 100%;

  .heading
  {
	@include layout.container-style('small');
	& {
	  margin: 0 auto .75em;
	}
  }

  ul
  {
	.child-page
	{
	  .permalink
	  {
		display: block;
		border-top: .1rem solid color.get-color(6);
		border-bottom: .1rem solid color.get-color(6);
		background: color.get-color(7);
		color: color.get-color(1);
		margin-bottom: -.1rem;
		padding: 1.5em 0;
		text-decoration: none;
		transition: background-color .25s ease-in-out;

		@include config.mediaquery(hover)
		{
		  background: color.get-color(8);
		}
	  }
	  .child-page-inner
	  {
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;

		@include layout.container-style('small');
		& {
		  margin: 0 auto;
		}

		&:after
		{
		  flex-grow: 0;
		  flex-shrink: 0;
		  justify-self: flex-end;

		  content: '';
		  display: block;
		  background: url("#{config.$path_images}child-page-arrow.svg") no-repeat 50% 50%;
		  background-size: cover;
		  margin-left: auto;
		  width: .8rem;
		  height: 1.5rem;

		  @include config.mediaquery(sm)
		  {
			width: 1.6rem;
			height: 3rem;
		  }
		}
	  }
	  .index
	  {
		flex-grow: 0;
		flex-shrink: 0;

		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;

		background: color.get-color(1);
		border-radius: 50%;
		color: color.get-color(2);
		margin-right: .5em;
		padding-top: .5rem;
		width: 4.6rem;
		height: 4.6rem;
		@include typography.text-size(32, 1em);
		@include typography.typography-1-bold();

		@include config.mediaquery(sm)
		{
		  padding-top: .8rem;
		  width: 6.5rem;
		  height: 6.5rem;
		  @include typography.text-size(48, 1em);
		}
	  }
	  .title
	  {
		display: block;
		position: relative;
		top: .2rem;
		@include config.medium-font-size();
		& {
		  line-height: 1em;
		}
		@include typography.typography-1-medium();
		&
		{
		  margin: 0;
		  padding-right: 1em;
		}
		@include config.mediaquery(sm)
		{
		  top: .4rem;
		  @include config.h2;
		  & {
			margin: 0;
		  }
		}
	  }

	  &.js-active
	  {
		pointer-events: none;
		.permalink
		{
		  background: color.get-color(8);
		}
	  }
	  &.num-digits-2
	  {
		.index
		{
		  padding-right: .05em;
		  @include typography.text-size(26, 1em);
		  & {
			letter-spacing: -.025em;
		  }

		  @include config.mediaquery(sm)
		  {
			padding-right: .125em;
			@include typography.text-size(42, 1em);
			& {
			  letter-spacing: -.085em;
			}
		  }
		}
	  }
	}
  }
}