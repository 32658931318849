// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.menu-overlay-toggler
{
  @include form.reset-default-button-style;

  &.mobile-toggler
  {
	@include config.mediaquery(sm)
	{
	  display: none;
	}
  }


  @include config.mediaquery(xs2sm)
  {
	// We need the Toggle to be shown on top of the title bar...
	position: fixed;
	top: 1.1rem;
	right: config.$inner_padding_xs;
	margin: env(safe-area-inset-top, 0) 0 0;

  }

  &
  {
	z-index: 999;

	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;

	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);

	margin-left: 1.5em;
	width: 2.5rem;
	height: 2.5rem;
	pointer-events: auto;

	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
  }

  @include config.mediaquery(sm)
  {
	position: relative;
	right: 0;
  }
  @include config.mediaquery(md)
  {
	margin-left: 3em;
  }

  .lines
  {
	position: relative;
	width: 100%;
  }

  .line
  {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	background: color.get-color(2);
	width: 100%;
	height: .2rem;

	transform-origin: 50% 50%;

	@include config.mediaquery(sm)
	{
	  background: color.get-color(1);
	}

	&:nth-child(1) // Top
	{
	  opacity: 1;
	  transform: translate3d(0,-.4rem,0);
	  transition: transform .2s ease-out;
	}
	&:nth-child(2) // Bottom
	{
	  transform: translate3d(0,.3rem,0);
	  transition: transform .2s ease-out;
	}
  }
}
body.no-padding
{
  .menu-overlay-toggler
  {
	.line
	{
	  background: color.get-color(2);
	}
  }
}