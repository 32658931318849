// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

#menu-overlay
{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 888;
  background: color.get-color(1);
  padding-bottom: 2em;
  width: 100%;
  height: calc(var(--app-height) - #{config.$height_header_xs});
  animation: antibulli19-reveal-menu-overlay .15s ease-in-out 1 forwards;
  overflow: hidden;

  .menu-overlay-scroll-area
  {
	position: relative;
	z-index: 1;
	height: calc(var(--app-height) - #{config.$height_header_xs});
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
  }

  &:before
  {
	content: '';
	display: block;

	background: inherit;
	@include layout.set-title_bar-heights();
	& {
	  width: 100%;
	}

	@include config.mediaquery(xs2sm)
	{
	  position: fixed;
	  top: env(safe-area-inset-top, 0);
	  left: 0;
	  z-index: 2;
	}
  }

  @keyframes antibulli19-reveal-menu-overlay {
	0% { transform: translate3d(100%,0,0); }
	100% { transform: translate3d(0,0,0); }
  }


  @include config.mediaquery(xs2sm)
  {
	.menu-overlay-inner
	{
	  position: relative;
	  z-index: 1;
	  @include layout.set-padding-top-from-title_bar-heights();
	  @include layout.set-padding-bottom-from-header-heights();
	}
  }

  @include config.mediaquery(sm)
  {
	@include layout.set-top-from-header-heights();
	&
	{
	  padding-bottom: 0;
	  height: calc(var(--app-height));
	  animation: antibulli19-reveal-menu-overlay-sm .15s ease-in-out 1 forwards;
	}

	@keyframes antibulli19-reveal-menu-overlay-sm {
	  0% { transform: translate3d(0,-100%,0); }
	  100% { transform: translate3d(0,0,0); }
	}

	&:before
	{
	  display: none;
	}
	.menu-overlay-inner
	{
	  display: flex;
	  flex-wrap: nowrap;
	  justify-content: space-between;
	  align-items: stretch;

	  @include layout.container-style('large');
	  &
	  {
		margin: 0 auto;
		padding-bottom: 2em;
	  }

	  .menu-blog
	  {
		position: relative;
		padding: 2.5em 1em;
		width: 33.333333%;

		&:before
		{
		  content: '';
		  display: block;
		  position: absolute;
		  top: 0;
		  right: 0;
		  z-index: -1;
		  background: inherit;
		  height: 100vh;
		  width: 50vw;
		}
	  }
	  .menu-sport,
	  .menu-antibulli
	  {
		padding: 2.5em 2.5em;
		width: 33.333333%;
	  }
	}
  }
}