// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.blog-9,
.blogpath-badminton
{
  .page-layout:after, // Titlebar "fake"
  #title-bar,
  #child-pages ul .child-page .index,
  .title-area .title-area-inner .index,
  #menu-blog
  {
	background: color.get-color(90);
  }

  #menu-overlay:before
  {
	background: color.get-color(90);
  }

  .loading-animation
  {
	.spinner
	{
	  border-color: rgba(color.get-color(90, false), .3);
	  border-left-color: color.get-color(90);
	}
  }
  .the-content ul,
  .the-content p
  {
	a
	{
	  color: color.get-color(90);
	}
  }

  #the-header
  {
	.menu-primary .menu .menu-item
	{
	  &.icon-badminton
	  {
		a
		{
		  .icon
		  {
			width: 2.4rem;
			height: 2.4rem;
		  }
		  .title
		  {
			display: block;
			margin-top: 0;
		  }
		  @include config.mediaquery(xs2sm)
		  {
			.title
			{
			  margin-top: .1rem;
			}
			.icon
			{
			  position: relative;
			  top: .2rem;
			  width: 2.2rem;
			  height: 2.2rem;
			}
		  }
		}
	  }
	  &.icon-badminton-racket
	  {
		a
		{
		  .icon
		  {
			position: relative;
			top: .1rem;
			transform: translate3d(0,0,0) scale(1.2) rotate(15deg);
			width: 2.4rem;
			height: 2.4rem;

			@include config.mediaquery(xs2sm)
			{
			  top: .3rem;
			  left: .2rem;
			  transform: translate3d(0,0,0) scale(1.1) rotate(15deg);
			}

			svg
			{
			  @include config.mediaquery(xs2sm)
			  {
				path,
				rect
				{
				  fill: color.get-color(2);
				}
			  }
			}
		  }
		}
	  }
	  &.icon-badminton-racket.js-active
	  {
		a
		{
		  .icon
		  {
			svg
			{
			  path,
			  rect
			  {
				fill: color.get-color(3);
			  }
			  @include config.mediaquery(xs2sm)
			  {
				path,
				rect
				{
				  fill: color.get-color(1);
				}
			  }
			}
		  }
		}
	  }
	}
  }

  #the-header
  {
	// Change the Antibulli logo
	.logo
	{
	  @include layout.aspect-ratio(3120,843);
	  &
	  {
		top: -.3rem;
		background: url("#{config.$path_images}blogs/9/logo-Badminton.svg") no-repeat 50% 50%;
		background-size: cover;
		width: 18rem;
	  }

	  .name
	  {
		display: none;
	  }
	}
  }

  @include config.mediaquery(xs2sm)
  {
	#the-header
	{
	  background: color.get-color(90);
	  border-color: color.get-color(90);

	  .menu-primary .menu .menu-item
	  {
		a
		{
		  color: color.get-color(2);
		}

		.icon svg
		{
		  path
		  {
			fill: color.get-color(2);
		  }
		}

		&.js-active
		{
		  a
		  {
			color: color.get-color(1);
		  }

		  .icon svg
		  {
			path
			{
			  fill: color.get-color(1);
			}
		  }
		}
		&.menu-home
		{
		  svg
		  {
			path
			{
			  stroke: color.get-color(2) !important;
			}
		  }
		  &.js-active
		  {
			svg
			{
			  path
			  {
				stroke: color.get-color(1) !important;
			  }
			}
		  }
		}
	  }
	}
  }
}

// Set white log on the title-bar (frontpage on ly)
@include config.mediaquery(xs2sm)
{
  body[data-template="page_frontpage_sport"][data-blog="9"],
  body[data-template="page_exercises"][data-blog="9"],
  body[data-template="page_activities"][data-blog="9"],
  body[data-template="page_coaching"][data-blog="9"]
  {
	#title-bar
	{
	  .title-bar-inner
	  {
		justify-content: flex-start;

		.title
		{
		  @include layout.aspect-ratio(3120,843);
		  &
		  {
			position: relative;
			top: -.2rem;
			background: url("#{config.$path_images}blogs/9/logo-Badminton-White.svg") no-repeat 50% 50%;
			background-size: cover;
			margin: 0;
			width: 10rem;

			opacity: 0;
			animation: antibulli-9-reveal-logo .2s ease-out .2s 1 forwards;
		  }

		  span
		  {
			display: none;
		  }
		}
	  }
	}
  }
}
@keyframes antibulli-9-reveal-logo {
  0% { opacity: 0; }
  100% { opacity: 1; }
}