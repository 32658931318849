// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.blog-5,
.blogpath-tennis
{
  .page-layout:after, // Titlebar "fake"
  #title-bar,
  #child-pages ul .child-page .index,
  .title-area .title-area-inner .index,
  #menu-blog
  {
	background: color.get-color(50);
  }

  #menu-overlay:before
  {
	background: color.get-color(51);
  }

  .loading-animation
  {
	.spinner
	{
	  border-color: rgba(color.get-color(50, false), .3);
	  border-left-color: color.get-color(50);
	}
  }
  .the-content ul,
  .the-content p
  {
	a
	{
	  color: color.get-color(50);
	}
  }

  #the-header
  {
	.menu-primary .menu .menu-item
	{
	  &.icon-tennis
	  {
		a
		{
		  .icon
		  {
			width: 2.2rem;
			height: 2.2rem;
		  }
		  .title
		  {
			display: block;
			margin-top: .2rem;
		  }
		  @include config.mediaquery(xs2sm)
		  {
			.icon
			{
			  width: 2rem;
			  height: 2rem;
			}
		  }
		}
	  }
	  &.icon-tennis.js-active
	  {
		a
		{
		  .icon
		  {
			svg
			{
			  path
			  {
				stroke: color.get-color(3);
			  }
			}
		  }
		}
	  }
	  &.icon-tennis-racket.js-active
	  {
		a
		{
		  .icon
		  {
			svg
			{
			  .st0-tennis-racket
			  {
				stroke: color.get-color(3);
			  }
			  polygon
			  {
				fill: color.get-color(3);
			  }
			}
		  }
		}
	  }
	}
  }

  #the-header
  {
	// Change the Antibulli logo
	.logo
	{
	  top: 0;
	  @include layout.aspect-ratio(552,132);
	  &
	  {
		background: url("#{config.$path_images}blogs/5/logo-md.png") no-repeat 50% 50%;
		background-size: cover;
		width: 27.6rem;
	  }

	  .name
	  {
		display: none;
	  }
	}
  }

  @include config.mediaquery(xs2sm)
  {
	#the-header
	{
	  background: color.get-color(51);
	  border-color: color.get-color(51);

	  .menu-primary .menu .menu-item
	  {
		a
		{
		  color: color.get-color(2);
		}

		.icon svg
		{
		  path
		  {
			fill: color.get-color(2);
		  }
		}

		&.js-active
		{
		  a
		  {
			color: color.get-color(1);
		  }

		  .icon svg
		  {
			path
			{
			  fill: color.get-color(1);
			}
		  }
		}




		&.icon-tennis.js-active
		{
		  a
		  {
			.icon
			{
			  svg
			  {
				path
				{
				  stroke: color.get-color(1);
				}
			  }
			}
		  }
		}
		&.icon-tennis-racket
		{
		  polygon
		  {
			fill: color.get-color(2);
		  }

		  line
		  {
			stroke: color.get-color(2);
		  }
		}
		&.icon-tennis-racket.js-active
		{
		  a
		  {
			.icon
			{
			  svg
			  {
				.st0-tennis-racket
				{
				  stroke: color.get-color(1);
				}
				polygon
				{
				  fill: color.get-color(1);
				}
			  }
			}
		  }
		}
		&.menu-home
		{
		  svg
		  {
			path
			{
			  stroke: color.get-color(2) !important;
			}
		  }
		  &.js-active
		  {
			svg
			{
			  path
			  {
				stroke: color.get-color(1) !important;
			  }
			}
		  }
		}
	  }
	}
  }
}

// Set white log on the title-bar (frontpage on ly)
@include config.mediaquery(xs2sm)
{
  body[data-template="page_frontpage_sport"][data-blog="5"],
  body[data-template="page_exercises"][data-blog="5"],
  body[data-template="page_activities"][data-blog="5"],
  body[data-template="page_coaching"][data-blog="5"]
  {
	#title-bar
	{
	  .title-bar-inner
	  {
		justify-content: flex-start;

		.title
		{
		  @include layout.aspect-ratio(300, 70);
		  &
		  {
			background: url("#{config.$path_images}blogs/5/logo-xs.png") no-repeat 50% 50%;
			background-size: cover;
			margin: 0;
			width: 15rem;

			opacity: 0;
			animation: antibulli-5-reveal-logo .2s ease-out .2s 1 forwards;
		  }

		  span
		  {
			display: none;
		  }
		}
	  }
	}
  }
}
@keyframes antibulli-5-reveal-logo {
  0% { opacity: 0; }
  100% { opacity: 1; }
}