@use "sass:math";

// ...
// Paths
$path_images: '../../images/';
$path_fonts: '../../fonts/';

// ...
// Media Query Breakpoints
@mixin mediaquery($size) {
  @if $size == xs {
	@media (max-width: 767px) { @content; }
  }
  @else if $size == sm {
	@media (min-width: 768px) { @content; }
  }
  @else if $size == md {
	@media (min-width: 1024px) { @content; }
  }
  @else if $size == lg {
	@media (min-width: 1240px) { @content; }
  }
  @else if $size == xlg {
	@media (min-width: 1680px) { @content; }
  }

  @else if $size == xs320 {
	@media (max-width: 374px) { @content; }
  }
  @else if $size == xsbig {
	@media (min-width: 640px) { @content; }
  }
  @else if $size == xs2xsbig {
	@media (max-width: 639px) { @content; }
  }
  @else if $size == xs2sm {
	@media (max-width: 767px) { @content; }
  }
  @else if $size == xs2md {
	@media (max-width: 1023px) { @content; }
  }
  @else if $size == sm2md {
	@media (min-width: 768px) and (max-width: 1023px) { @content; }
  }
  @else if $size == sm2lg {
	@media (min-width: 768px) and (max-width: 1239px) { @content; }
  }
  @else if $size == md2lg {
	@media (min-width: 1024px) and (max-width: 1239px) { @content; }
  }

  @else if $size == hover {
	@media (hover: hover) { &:hover { @content; } }
  }

  @else if $size == portrait {
	@media only screen and (orientation: portrait) { @content; }
  }
  @else if $size == landscape {
	@media only screen and (orientation: landscape) { @content; }
  }
}

// ...
// Colors
$colors: (
  0: #000,
  1: #000,
  2: #FFF,
  3: #b3a13d,
  4: #ebeae8, // Body
  5: #636363, // Logo tagline, Primary Menu
  6: #ebeae8, // ChildPages border top
  7: #f2f2f2, // ChildPages background
  8: #dddddd, // ChildPages background hover

  // Håndbold (blog 2)
  20: #E3C583,//#dc9d2f,
  21: #EACB87,//#e8ad47,

  //Mørk: #E3C583
  //Lys: #EACB87

  // Svømning (blog 3)
  30: #02a3de,
  31: #2ac0eb,

  // Fodbold (blog 4)
  40: #009b3e,
  41: #32a43f,

  // Tennis (blog 5)
  50: #e47e2a,
  51: #ff9a46,

  // Ridning (blog 6)
  60: #605A4C,
  61: #938769,

  // Gymnastik (blog 7)
  70: #C20012,
  71: #637CBC,

  // Kroket - TEST SITE (blog 8)
  80: #D8000C,
  81: #F2E34C,

  // Badminton (blog 9)
  90: #006F2A,
  91: #004023,
  92: #E31E38,
);
$color_error: #D8000C;
$colors_filtered: (0,1,2,3,4,5,6,20,21,30,31,40,41,50,51,60,61,70,71,90,91,92);

$useRootColors: true; // Globally boolean to define whether to user #000 or var(--color-0). May be individually overridden in get-color()

// ...
// Misc. properties

// Padding from body sides to content
$inner_padding_xs: 2rem;
$inner_padding_sm: 4rem;
$inner_padding_md: 4rem;
$inner_padding_lg: 7rem;

// Small container size (inner padding is appended)
$max_width_container_small_xs: 77.2rem;
$max_width_container_small_sm: 77.2rem;
$max_width_container_small_md: 77.2rem;
$max_width_container_small_lg: 77.2rem;

// Medium container size (inner padding is appended)
$max_width_container_medium_xs: 90rem; // unverified
$max_width_container_medium_sm: 90rem; // unverified
$max_width_container_medium_md: 90rem; // unverified
$max_width_container_medium_lg: 90rem; // unverified

// Large container size (inner padding is appended)
$max_width_container_large_xs: 117rem;
$max_width_container_large_sm: 117rem;
$max_width_container_large_md: 117rem;
$max_width_container_large_lg: 117rem;

// Huge container size (inner padding is appended)
$max_width_container_huge_xs: 14.8rem; // unverified
$max_width_container_huge_sm: 14.8rem; // unverified
$max_width_container_huge_md: 14.8rem; // unverified
$max_width_container_huge_lg: 14.8rem; // unverified

// Content size (Typically paragraphs, lists etc...)
$max_width_inner_content_xs: 76rem;
$max_width_inner_content_sm: 76rem;
$max_width_inner_content_md: 76rem;
$max_width_inner_content_lg: 76rem;

$max_width_content_xs: 90rem; // unverified
$max_width_content_sm: 90rem; // unverified
$max_width_content_md: 90rem; // unverified
$max_width_content_lg: 90rem; // unverified

// Useful if fixed header
$height_header_xs: 5rem; // Positioned fixed at the bottom of the page...
$height_header_sm: 9rem;
$height_header_md: 9rem;
$height_header_lg: 9rem;

// Useful if fixed header
$height_title_bar_xs: 4.7rem;
$height_title_bar_sm: 4.4rem;
$height_title_bar_md: 4.4rem;
$height_title_bar_lg: 4.4rem;

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
	@return math.div($number, $number * 0 + 1);
  }
  @return $number;
}

// ...
// Fonts
$font_family_1__Light : 'subset-DINNextLTPro-Light';
$font_family_1__Medium : 'subset-DINNextLTPro-Medium';
$font_family_1__Bold : 'subset-DINNextLTPro-Bold';

@mixin huge-font-size() {
  & {
	line-height: 5.2rem;
	font-size: 4rem;
  }

  @include mediaquery(sm) {
	font-size: 5.2rem;
	line-height: 6.8rem;
  }
}

@mixin large-font-size() {
  & {
	font-size: 2.4rem;
	line-height: 3.1rem;
  }

  @include mediaquery(sm) {
	font-size: 3.4rem;
	line-height: 4.4rem;
  }
}

@mixin medium-font-size() {
  & {
	font-size: 2.2rem;
	line-height: 2.8rem;
  }

  @include mediaquery(sm) {
	font-size: 2.8rem;
	line-height: 3.5rem;
  }
}

@mixin normal-font-size() {
  & {
	font-size: 1.6rem;
	line-height: 2.1rem;
  }

  @include mediaquery(sm) {
	font-size: 2rem;
	line-height: 2.6rem;
  }
}

@mixin small-font-size() {
  & {
	font-size: 1.4rem;
	line-height: 1.8rem;
  }

  @include mediaquery(sm) {
	font-size: 1.6rem;
	line-height: 2.1rem;
  }
}

@mixin tiny-font-size() {
  & {
	font-size: 1.2rem;
	line-height: 1.6rem;
  }

  @include mediaquery(sm) {
	font-size: 1.4rem;
	line-height: 1.9rem;
  }
}

@mixin h1() {
  & {
	margin-bottom: .5em;
	font-size: 2.5rem;
	line-height: 2.5rem;
  }

  @include mediaquery(sm) {
	font-size: 4rem;
	line-height: 4rem;
  }

  @include mediaquery(md) {
	font-size: 5.2rem;
	line-height: 5.2rem;
  }
}

@mixin h2() {
  & {
	margin-bottom: .5em;
	font-size: 2.4rem;
	line-height: 2.4rem;
  }

  @include mediaquery(sm) {
	font-size: 2.8rem;
	line-height: 2.8rem;
  }

  @include mediaquery(md) {
	font-size: 3.4rem;
	line-height: 3.4rem;
  }
}

@mixin h3() {
  & {
	margin-bottom: .25em;
	font-size: 2rem;
	line-height: 2.2rem;
  }

  @include mediaquery(sm) {
	font-size: 2.4rem;
	line-height: 2.6rem;
  }

  @include mediaquery(md) {
	font-size: 2.8rem;
	line-height: 3rem;
  }
}

@mixin h4() {
  & {
	margin-bottom: .25em;
	font-size: 1.8rem;
	line-height: 1.8rem;
  }

  @include mediaquery(sm) {
	font-size: 2rem;
	line-height: 2rem;
  }

  @include mediaquery(md) {
	font-size: 2.4rem;
	line-height: 2.4rem;
  }
}

@mixin h5() {
  & {
	margin-bottom: .125em;
	font-size: 1.8rem;
	line-height: 2.2rem;
  }

  @include mediaquery(sm) {
	font-size: 2rem;
	line-height: 2.4rem;
  }

  @include mediaquery(md) {
	font-size: 2.2rem;
	line-height: 2.6rem;
  }
}

@mixin h6() {
  @include normal-font-size();
}

/*
.ReactQueryDevtools
{
  visibility: hidden;
}
*/