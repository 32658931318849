// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.the-content
{
  position: relative;
  @include layout.container-style('small');
  &
  {
	margin: 0 auto;
	padding-top: 3em;
	padding-bottom: 3em;
  }

  // Default content
  p,
  ul li,
  ol li,
  h1,h2,h3,h4,h5,h6
  {
	&.has-text-color
	{
	  a
	  {
		color: inherit;
	  }
	}
	&.no-padding
	{
	  padding-bottom: 0;
	}
	&.no-margin
	{
	  margin-bottom: 0;
	}
  }
  h1,h2,h3,h4,h5,h6
  {
	&:first-child
	{
	  margin-top: 1rem;
	}
	&.has-background
	{
	  @include config.mediaquery(xs2sm)
	  {
		padding: 2.4rem !important;
	  }
	}
  }
  h1:first-child
  {
	margin-top: -.3em;
	margin-bottom: .675em;
  }
  p + *:not(p).has-huge-font-size
  {
	margin-top: -.75em;
  }
  p.has-background
  {
	padding: 3.2rem !important;
	@include config.mediaquery(xs2sm)
	{
	  padding: 2.4rem !important;
	}
  }
  & > p:last-child
  {
	padding-bottom: 0;
  }

  // Lists
  ol,
  ul
  {
	margin-bottom: 2.6rem;

	&:last-child
	{
	  padding-bottom: 0;
	}
	&:last-child
	{
	  margin-bottom: 0;
	}
	li ol:last-child,
	li ul:last-child
	{
	  padding-bottom: 0;
	}
  }
  ol
  {
	counter-reset: item;

	li
	{
	  position: relative;
	  margin-left: 0;
	  padding-left: 1.5em;

	  &:not(:last-child)
	  {
		margin-bottom: .5em;
	  }

	  &:before
	  {
		content: counters(item, ".") ". ";
		counter-increment: item;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 2rem;
		text-align: right;
		white-space: nowrap;
	  }

	  ul,ol
	  {
		margin: 0 0 1em .875em;
		padding: 0 0 1em;

		li
		{
		  padding-left: 1.75em;
		  &:first-child
		  {
			margin-top: .5em;
		  }
		  &:last-child
		  {
			margin-bottom: .5em;
		  }
		}
	  }
	}
  }
  ul
  {
	li
	{
	  &:not(:last-child)
	  {
		margin-bottom: .5em;
	  }
	}
	& > li
	{
	  position: relative;
	  padding-left: .75em;

	  &:before
	  {
		content: '';
		display: block;
		position: absolute;
		top: .4em;
		left: 0;
		background: color.get-color(1);
		border-radius: 50%;
		width: .6rem;
		height: .6rem;
	  }

	  ul,ol
	  {
		margin: 0 0 0 .875em;
		padding: .5em 0;
	  }
	}
  }

  //
  // Expanded collapsed
  .read-more-btn,
  .read-less-btn
  {
	@include form.reset-default-button-style();
	&
	{
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: center;
	  align-items: flex-start;
	  color: color.get-color(5);
	  margin: 0 auto;
	}
	@include config.small-font-size();
	@include typography.typography-1-light(true);

	span
	{
	  display: block;
	  width: 100%;
	}
	&:after
	{
	  content: '';
	  display: block;
	  background: url("#{config.$path_images}read-more-btn-arrow.svg") no-repeat 50% 50%;
	  background-size: cover;
	  margin: .125em 0 0;
	  width: 1.8rem;
	  height: 1rem;
	  opacity: .7;
	}
  }

  .read-less-btn
  {
	display: none;
  }
  .read-more-btn
  {
	& ~ *
	{
	  display: none;
	}
  }
  &.js-expanded
  {
	.read-more-btn
	{
	  display: none;
	  & ~ *:not(.read-less-btn)
	  {
		display: block;
		animation: read-more-content-revealed .3s ease-out 1 forwards;
	  }
	}
	.read-less-btn
	{
	  display: flex;
	  span
	  {
		order: 2;
	  }
	  &:after
	  {
		order: 1;
		transform: translate3d(0,0,0) scaleY(-1);
		margin: 0 0 .25em;
	  }
	}
  }
  @keyframes read-more-content-revealed {
	0% { transform: translate3d(0,-1em,0); opacity: 0; }
	100% { transform: translate3d(0,0,0); opacity: 1; }
  }
}