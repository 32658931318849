// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.wp-block-pullquote
{
  position: relative;

  border-top: 1rem solid color.get-color(3);
  border-bottom: 1rem solid color.get-color(3);
  margin-top: 2em;
  margin-bottom: 2em;

  @include color.set-gutenberg-border-colors();

  &
  {
	padding: 0;
	text-align: left;
  }

  &:not(.is-style-solid-color) // Default, no background
  {
	@include color.set-gutenberg-colors();
  }

  blockquote
  {
	max-width: 100% !important;
	margin-bottom: 0;
	padding: 1.5em;

	p
	{
	  color: inherit;
	  @include config.large-font-size();
	  @include typography.typography-1-medium(true, true);
	  &
	  {
		margin-bottom: 0;
		padding: 0;
		line-height: 1.25em;
	  }

	  &:last-of-type
	  {
		margin-bottom: 0;
		padding-bottom: 0;
	  }
	}
  }
  &.has-text-align-left
  {
	p,
	cite
	{
	  text-align: left;
	}
  }
  &.has-text-align-center
  {
	p,
	cite
	{
	  text-align: center;
	}
  }
  &.has-text-align-right
  {
	p,
	cite
	{
	  text-align: right;
	}
  }
}