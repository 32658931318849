// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.fullscreen-button
{
  @include form.reset-default-button-style;
  @include form.no-appearance;
  &
  {
	display: inline-flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-end;

	position: absolute;
	right: calc(20% + 0.1rem);
	bottom: 1em;
	z-index: 666;
	color: color.get-color(2);
	transform: translate3d(100%, 0, 0);
  }
  @include typography.typography-1-light(true);
  @include config.small-font-size();

  &:before
  {
	content: '';
	display: block;
	background: url("#{config.$path_images}fullscreen-button.svg") no-repeat 50% 50%;
	background-size: cover;
	margin-right: .5em;
	width: 3rem;
	height: 3rem;
  }
  .label
  {
	position: relative;
	top: .4rem;
  }
}