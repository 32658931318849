// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.wp-block-quote
{
  @include color.set-gutenberg-colors;
  &
  {
	margin-top: 2em !important;
	margin-bottom: 2em !important;
  }

  p
  {
	text-align: left;
	font-style: normal;
	@include config.large-font-size();
	@include typography.typography-1-medium(true,true);

	&:last-of-type
	{
	  margin-bottom: 0;
	  padding-bottom: 0;
	}
  }
  cite
  {
	text-align: left;
  }

  &:not(.is-style-large):not(.is-style-plain)
  {
	//padding-left: 1.5em;
	//border-left: .4rem solid color.get-color(3);
	p
	{
	  @include config.large-font-size();
	  &
	  {
		margin-bottom: 0;
		padding-bottom: 0;
		font-style: normal;
	  }

	  &:last-of-type
	  {
		margin-bottom: 0;
		padding-bottom: 0;
	  }
	}
  }

  &.is-style-large
  {
	padding: 0;
	p
	{
	  color: color.get-color(1);
	  @include config.large-font-size();
	  &
	  {
		margin-bottom: 0;
		padding-bottom: 0;
		font-style: normal;
	  }

	  &:last-of-type
	  {
		margin-bottom: 0;
		padding-bottom: 0;
	  }
	}
  }
  &.is-style-plain
  {
	padding: 0;
	p
	{
	  @include config.large-font-size();
	  &
	  {
		color: color.get-color(1);
		margin-bottom: 0;
		padding-bottom: 0;
	  }

	  &:last-of-type
	  {
		margin-bottom: 0;
		padding-bottom: 0;
	  }
	}
  }

  &.has-text-align-left
  {
	p,
	cite
	{
	  text-align: left;
	}
  }
  &.has-text-align-center
  {
	p,
	cite
	{
	  text-align: center;
	}
  }
  &.has-text-align-right
  {
	p,
	cite
	{
	  text-align: right;
	}
  }

  //
  // With background
  &.has-background
  {
	padding: 1.5em;
  }
  &.has-text-color
  {
	p
	{
	  color: inherit;
	}
  }
}