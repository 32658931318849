// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

body[data-blog="1"]
{
  background: color.get-color(1);
}
.blog-1
{
  //
  // Never show the fixed bottom navigation on mobile
  #the-header
  {
	#menu-primary
	{
	  display: none;
	}
  }
  #menu-overlay
  {
	height: var(--app-height);
  }
  @include config.mediaquery(xs2sm)
  {
	#the-header
	{
	  border: none;
	  background: none;
	}
  }


  //
  // Use dark background and light text colors
  .page-layout
  {
	background: color.get-color(1);
	&#page-layout-loading
	{
	  background: color.get-color(1);
	}
	.page-layout-inner
	{
	  background: color.get-color(1);
	  color: color.get-color(2);
	}
	&:not(.is-home)
	{
	  &:before,
	  &:after
	  {
		background: color.get-color(1);
	  }
	}
	&:after
	{
	  background: color.get-color(1) !important;
	}
  }
  h1,h2,h3,h4,h5,h6,figcaption,.figcaption,cite
  {
	color: color.get-color(2);
  }
  .wp-block-buttons .wp-block-button button:hover,
  .wp-block-buttons .wp-block-button a:hover,
  .wp-block-buttons .wp-block-button .wp-block-button__link:hover
  {
	background: color.get-color(2) !important;
	color: color.get-color(1) !important;
  }
  .wp-block-table.is-style-stripes tbody tr:nth-child(odd)
  {
	background: color.get-color(2);
	color: color.get-color(1);
  }
  .loading-animation
  {
	.spinner
	{
	  border-color: rgba(255,255,255,.3);
	  border-left-color: color.get-color(2);
	}
  }

  .the-content,
  .CookieDeclaration
  {
	ul
	{
	  & > li
	  {
		&:before
		{
		  background: color.get-color(2);
		}
	  }
	}
	ul,
	p
	{
	  a
	  {
		color: color.get-color(2);
	  }
	}
  }
  .CookieDeclaration
  {
	a[style]
	{
	  font-size: 2rem !important;
	}
  }
}