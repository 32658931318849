// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.logo
{
  display: inline-block;
  vertical-align: top;
  color: color.get-color(1);
  text-decoration: none;

  .name
  {
	display: block;
	@include typography.typography-1-bold();
	@include typography.text-size(32,1em);
	& {
	  text-transform: uppercase;
	}
  }
  .tagline
  {
	display: block;
	@include typography.typography-1-light();
	@include typography.text-size(12,1em);
  }
}