// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.cookie-consent-needed
{
  &,
  .cookie-consent-needed-inner
  {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
  }

  position: absolute;
  inset: 0 0 0 0;
  z-index: 888;

  color: color.get-color(2);
  @include layout.set-inner-padding-sides(1,1,1,1);

  .text
  {
	width: 100%;
	text-align: center;
  }

  .cookie-consent-button
  {
	@include form.default-button-style();
	& {
	  margin: 1em auto;
	}

	@include config.mediaquery(hover)
	{
	  background: color.get-color(3) !important;
	  border-color: color.get-color(3) !important;
	  color: color.get-color(1) !important;
	}
  }
}