// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
//@use "../Mixins/Form" as form;
//@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

@mixin placeholder($color, $color_invalid) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  /*
  &.js-invalid {
    color: $color_invalid;
  }
  &.js-invalid::-webkit-input-placeholder {
    color: $color_invalid;
  }
  &.js-invalid::-moz-placeholder {
    color: $color_invalid;
  }
  &.js-invalid:-ms-input-placeholder {
    color: $color_invalid;
  }
  */
}

@mixin js-error() {

  &
  {
	backface-visibility: hidden;
	animation: app-shake-js-error .75s cubic-bezier(.36, .07, .19, .97) both;
  }

  // Setup key frame animation
  @keyframes app-shake-js-error {
	0% { transform: translate3d(.5rem, .1rem, 0); }
	10% { transform: translate3d(-.1rem, -.4rem, 0); }
	20% { transform: translate3d(-.3rem, 0rem, 0); }
	30% { transform: translate3d(0, .3rem, 0); }
	40% { transform: translate3d(.1rem, -.1rem, 0); }
	50% { transform: translate3d(-.1rem, .2rem, 0); }
	60% { transform: translate3d(-.5rem, .1rem, 0); }
	70% { transform: translate3d(.2rem, .1rem, 0); }
	80% { transform: translate3d(-.2rem, -.1rem, 0); }
	90% { transform: translate3d(.2rem, .2rem, 0); }
	100% { transform: translate3d(.3rem, -.2rem, 0); }
  }
}

@mixin no-appearance() {
  &
  {
	-webkit-appearance: none;
	-moz-appearance: none;
  }
}

@mixin user-select-none() {
  &
  {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
}

@mixin default-button-style() {
  @include reset-default-button-style();

  &
  {
	display: inline-flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;

	position: relative;
	background: color.get-color(3);
	border-radius: 0;
	border: .1rem solid transparent;
	color: color.get-color(1);
	//padding: .9em 3.25em;
	padding: .75em 1.5em .6em;

	text-decoration: none;
  }
  @include typography.typography-1-medium(true,true);
  @include config.normal-font-size();

  & {
	transition: background-color .2s ease-out, color .2s ease-out, border .2s ease-out;
  }

  // Coloring
  @each $name, $color in config.$colors
  {

	// Text
	&.has-color-#{$name}-color{
	  @if(config.$useRootColors) {
		border-color: var(--color-#{$name});
		color: var(--color-#{$name});
		svg
		{
		  path
		  {
			fill: var(--color-#{$name});
		  }
		}
	  }
	  @else {
		border-color: $color;
		color: $color;
		svg
		{
		  path
		  {
			fill: $color;
		  }
		}
	  }
	}

	// Background
	&.has-color-#{$name}-background-color {
	  @if(config.$useRootColors) {
		background: var(--color-#{$name});
	  }
	  @else {
		background: $color;
	  }
	}
  }

  @include config.mediaquery(hover)
  {
	background: color.get-color(1) !important;
	border-color: color.get-color(1) !important;
	color: color.get-color(2) !important;
  }

  span
  {
	pointer-events: none;
  }
}

@mixin reset-default-button-style() {
  &
  {
	background: none;
	border: none;
	border-radius: 0;
	//outline: none;
  }
  @include no-appearance;
  &
  {
	-webkit-tap-highlight-color: transparent !important;
	-webkit-backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
	cursor: pointer;
	margin: 0;
	padding: 0;
  }
}