// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

@include config.mediaquery(xs2sm)
{
  body:has(.has-hotjar-popup)
  {
	#Coi-Renew,
	#the-header
	{
	  margin-bottom: 4.4rem;
	}
  }
  .has-hotjar-popup
  {
	#Coi-Renew,
	#the-header
	{
	  margin-bottom: 4.4rem;
	}
  }
}