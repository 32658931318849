// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;


// ...
// Load locale fonts
@include typography.font-face(config.$font_family_1__Light, config.$font_family_1__Light);
@include typography.font-face(config.$font_family_1__Medium, config.$font_family_1__Medium);
@include typography.font-face(config.$font_family_1__Bold, config.$font_family_1__Bold);

:root
{
  @include color.set-root-colors;
  --app-height: 100%;
}
*,
*:before,
*:after
{
  box-sizing: border-box;
}
html
{
  min-height: 100%;
  font-size: 62.5%;
}
body
{
  @include typography.text-rendering; // Disable font-smoothing?!
  @include typography.typography-1-light(true, true);
  @include config.normal-font-size();

  @include color.set-gutenberg-colors;
  @include color.set-background-color(4);
  @include color.set-color(1);
}

h1,h2,h3,h4,h5,h6
{
  color: color.get-color(1);
}
h1,.h1,.has-h-1-font-size,
h2,.h2,.has-h-2-font-size,
h3,.h3,.has-h-3-font-size,
h4,.h4,.has-h-4-font-size,
h5,.h5,.has-h-5-font-size,
h6,.h6,.has-h-6-font-size {
  @include typography.typography-1-medium(true,true);
  strong
  {
	@include typography.typography-1-bold(true,true);
  }
  &.has-background
  {
	margin-top: 2.5rem;
	margin-bottom: 5rem;
	padding: 4rem !important;
  }
  a
  {
	text-decoration: none;
  }
}
h1,.h1,.has-h-1-font-size {
  @include config.h1;
}
h2,.h2,.has-h-2-font-size {
  @include config.h2;
}
h3,.h3,.has-h-3-font-size {
  @include config.h3;
}
h4,.h4,.has-h-4-font-size {
  @include config.h4;
}
h5,.h5,.has-h-5-font-size {
  @include config.h5;
}
h6,.h6,.has-h-6-font-size {
  @include config.h6;
}
.has-large-font-size {
  @include config.large-font-size();
}
.has-normal-font-size {
  @include config.normal-font-size();
}
.has-small-font-size {
  @include config.small-font-size();
}
.has-tiny-font-size {
  @include config.tiny-font-size();
}

a {
  color: color.get-color(3);
  text-decoration: underline;
  text-decoration-thickness: 0.1rem;
  text-underline-position: under;

  &[href^="tel:"]
  {
	text-decoration: none;
  }
}
strong {
  @include typography.typography-1-bold(true, true);
}
i,
em {
  font-style: italic;
}
p {
  margin-bottom: 2.6rem;
  &.has-background
  {
	margin-bottom: 5.2rem;
  }
}
figcaption,
.figcaption,
cite
{
  display: block;
  color: color.get-color(1);
  padding: .5em 0 0;
  margin-bottom: 0 !important;
  width: 100%;
  text-align: left;
  @include typography.typography-1-light();
  @include config.small-font-size();
}
sub,
sup
{
  position: relative;
  vertical-align: baseline;
  line-height: 0;
  font-size: 75%;
}
sup
{
  top: -.5em;
}
sub
{
  bottom: -.25em;
}