@use 'sass:math';

// ...
// Configuration + Mixins
@use "../Config" as config;

//@use "../Mixins/Color" as color;
//@use "../Mixins/Form" as form;
//@use "../Mixins/Layout" as layout;
//@use "../Mixins/Typography" as typography;

// ...
// ASPECT RATIOS
@mixin aspect-ratio($w, $h, $before: true) {

  & {
	overflow: hidden;
  }

  @if $before {
	&:before {
	  content: '';
	  display: block;
	  padding-top: math.percentage(math.div($h,$w));
	  overflow: hidden;
	}
  }
  @else {
	&:after {
	  content: '';
	  display: block;
	  padding-top: math.percentage(math.div($h,$w));
	  overflow: hidden;
	}
  }
}

@mixin no-aspect-ratio($before: true) {

  @if $before {
	&:before {
	  display: inherit;
	  content: inherit;
	  padding-top: inherit;
	}
  }
  @else {
	&:after {
	  display: inherit;
	  content: inherit;
	  padding-top: inherit;
	}
  }
}

@mixin set-16-9-iframe() {
  & {
	position: relative;
  }
  @include aspect-ratio(16, 9);

  iframe
  {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 111;
	vertical-align: top;
	width: 100%;
	height: 100%;
  }
}

// ...
// FULLSCREEN MANIPULATION
@mixin move-off-screen() {
  &
  {
	position: absolute;
	left: -99999rem;
  }
}
@mixin make-fullscreen() {
  &
  {
	position: relative;
	left: 50%; // may have to be set to 0 because if flex-direction: column;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: none;
	width: 100vw;
  }
}
@mixin disable-fullscreen() {
  &
  {
	position: relative;
	left: auto;
	right: auto;
	margin-left: auto;
	margin-right: auto;
	max-width: none;
	width: auto;
  }
}
@mixin make-fullscreen-relative-to-the-editor-content() {
  &
  {
	position: relative;
	left: -#{config.$inner_padding_xs};
	width: calc(100% + #{config.$inner_padding_xs * 2});
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	&
	{
	  left: -#{config.$inner_padding_sm};
	  width: calc(100% + #{config.$inner_padding_sm * 2});
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	&
	{
	  left: -#{config.$inner_padding_md};
	  width: calc(100% + #{config.$inner_padding_md * 2});
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	&
	{
	  left: -#{config.$inner_padding_lg};
	  width: calc(100% + #{config.$inner_padding_lg * 2});
	}
  }
}

// ...
// MAX HEIGHTS
@mixin set-max-height-from-viewport($vh: 100) {
  // Set a max height
  & {
	max-height: calc(#{$vh}vh - #{config.$height_header_xs});
  }
  @if(config.$height_header_sm != config.$height_header_xs)	{
	@include config.mediaquery(sm) {
	  max-height: calc(#{$vh}vh - #{config.$height_header_sm});
	}
  }
  @if(config.$height_header_md != config.$height_header_sm)	{
	@include config.mediaquery(md) {
	  max-height: calc(#{$vh}vh - #{config.$height_header_md});
	}
  }
  @if(config.$height_header_lg != config.$height_header_md)	{
	@include config.mediaquery(lg) {
	  max-height: calc(#{$vh}vh - #{config.$height_header_lg});
	}
  }
}
@mixin set-height-from-viewport($vh: 100) {
  // Set a height
  & {
	height: calc(#{$vh}vh - #{config.$height_header_xs});
  }
  @if(config.$height_header_sm != config.$height_header_xs)	{
	@include config.mediaquery(sm) {
	  height: calc(#{$vh}vh - #{config.$height_header_sm});
	}
  }
  @if(config.$height_header_md != config.$height_header_sm)	{
	@include config.mediaquery(md) {
	  height: calc(#{$vh}vh - #{config.$height_header_md});
	}
  }
  @if(config.$height_header_lg != config.$height_header_md)	{
	@include config.mediaquery(lg) {
	  height: calc(#{$vh}vh - #{config.$height_header_lg});
	}
  }
}

// ...
// MAX WIDTHS AND CONTAINER STYLES
@mixin container-style($size: 'large', $excludePadding: false) {

  @if($excludePadding == false) {
	@include set-inner-padding-right(1);
	@include set-inner-padding-left(1);
  }

  @if($size == 'small') {
	@include set-max-width-container-small($excludePadding);
  }
  @else if($size == 'medium') {
	@include set-max-width-container-medium($excludePadding);
  }
  @else if($size == 'huge') {
	@include set-max-width-container-huge($excludePadding);
  }
  @else {
	@include set-max-width-container-large($excludePadding);
  }
  & {
	width: 100%;
  }
}
@mixin set-max-width-container-small($excludePadding: false) {
  @if($excludePadding == false) {
	& {
	  max-width: calc(#{config.$max_width_container_small_xs} + #{config.$inner_padding_xs} * 2);
	}
  }
  @else {
	& {
	  max-width: config.$max_width_container_small_xs;
	}
  }
  @if(config.$max_width_container_small_sm != config.$max_width_container_small_xs or config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  @if($excludePadding == false) {
		max-width: calc(#{config.$max_width_container_small_sm} + #{config.$inner_padding_sm} * 2);
	  }
	  @else {
		max-width: config.$max_width_container_small_sm;
	  }
	}
  }
  @if(config.$max_width_container_small_md != config.$max_width_container_small_sm or config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  @if($excludePadding == false) {
		max-width: calc(#{config.$max_width_container_small_md} + #{config.$inner_padding_md} * 2);
	  }
	  @else {
		max-width: config.$max_width_container_small_md;
	  }
	}
  }
  @if(config.$max_width_container_small_lg != config.$max_width_container_small_md or config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  @if($excludePadding == false) {
		max-width: calc(#{config.$max_width_container_small_lg} + #{config.$inner_padding_lg} * 2);
	  }
	  @else {
		max-width: config.$max_width_container_small_lg;
	  }
	}
  }
}
@mixin set-max-width-container-medium($excludePadding: false) {

  @if($excludePadding == false) {
	& {
	  max-width: calc(#{config.$max_width_container_medium_xs} + #{config.$inner_padding_xs} * 2);
	}
  }
  @else {
	& {
	  max-width: config.$max_width_container_medium_xs;
	}
  }
  @if(config.$max_width_container_medium_sm != config.$max_width_container_medium_xs or config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  @if($excludePadding == false) {
		 max-width: calc(#{config.$max_width_container_medium_sm} + #{config.$inner_padding_sm} * 2);
	   }
	  @else {
		 max-width: config.$max_width_container_medium_sm;
	   }
	}
  }
  @if(config.$max_width_container_medium_md != config.$max_width_container_medium_sm or config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  @if($excludePadding == false) {
		 max-width: calc(#{config.$max_width_container_medium_md} + #{config.$inner_padding_md} * 2);
	   }
	  @else {
		 max-width: config.$max_width_container_medium_md;
	   }
	}
  }
  @if(config.$max_width_container_medium_lg != config.$max_width_container_medium_md or config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  @if($excludePadding == false) {
		 max-width: calc(#{config.$max_width_container_medium_lg} + #{config.$inner_padding_lg} * 2);
	   }
	  @else {
		 max-width: config.$max_width_container_medium_lg;
	   }
	}
  }
}
@mixin set-max-width-container-large($excludePadding: false) {
  @if($excludePadding == false) {
	& {
	  max-width: calc(#{config.$max_width_container_large_xs} + #{config.$inner_padding_xs} * 2);
	}
  }
  @else {
	& {
	  max-width: config.$max_width_container_large_xs;
	}
  }
  @if(config.$max_width_container_large_sm != config.$max_width_container_large_xs or config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  @if($excludePadding == false) {
		max-width: calc(#{config.$max_width_container_large_sm} + #{config.$inner_padding_sm} * 2);
	  }
	  @else {
		max-width: config.$max_width_container_large_sm;
	  }
	}
  }
  @if(config.$max_width_container_large_md != config.$max_width_container_large_sm or config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  @if($excludePadding == false) {
		max-width: calc(#{config.$max_width_container_large_md} + #{config.$inner_padding_md} * 2);
	  }
	  @else {
		max-width: config.$max_width_container_large_md;
	  }
	}
  }
  @if(config.$max_width_container_large_lg != config.$max_width_container_large_md or config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  @if($excludePadding == false) {
		max-width: calc(#{config.$max_width_container_large_lg} + #{config.$inner_padding_lg} * 2);
	  }
	  @else {
		max-width: config.$max_width_container_large_lg;
	  }
	}
  }
}

@mixin set-max-width-container-huge($excludePadding: false) {
  @if($excludePadding == false) {
	& {
	  max-width: calc(#{config.$max_width_container_huge_xs} + #{config.$inner_padding_xs} * 2);
	}
  }
  @else {
	& {
	  max-width: config.$max_width_container_huge_xs;
	}
  }
  @if(config.$max_width_container_huge_sm != config.$max_width_container_huge_xs or config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  @if($excludePadding == false) {
		max-width: calc(#{config.$max_width_container_huge_sm} + #{config.$inner_padding_sm} * 2);
	  }
	  @else {
		max-width: config.$max_width_container_huge_sm;
	  }
	}
  }
  @if(config.$max_width_container_huge_md != config.$max_width_container_huge_sm or config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  @if($excludePadding == false) {
		max-width: calc(#{config.$max_width_container_huge_md} + #{config.$inner_padding_md} * 2);
	  }
	  @else {
		max-width: config.$max_width_container_huge_md;
	  }
	}
  }
  @if(config.$max_width_container_huge_lg != config.$max_width_container_huge_md or config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  @if($excludePadding == false) {
		max-width: calc(#{config.$max_width_container_huge_lg} + #{config.$inner_padding_lg} * 2);
	  }
	  @else {
		max-width: config.$max_width_container_huge_lg;
	  }
	}
  }
}

@mixin set-width-100-excluding-padding() {
  & {
	width: calc(100% - #{config.$inner_padding_xs} * 2);
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  width: calc(100% - #{config.$inner_padding_sm} * 2);
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  width: calc(100% - #{config.$inner_padding_md} * 2);
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  width: calc(100% - #{config.$inner_padding_lg} * 2);
	}
  }
}
@mixin set-max-width-content() {
  & {
	max-width: config.$max_width_content_xs;
  }

  @if(config.$max_width_content_sm != config.$max_width_content_xs) {
	@include config.mediaquery(sm) {
	  max-width: config.$max_width_content_sm;
	}
  }
  @if(config.$max_width_content_md != config.$max_width_content_sm) {
	@include config.mediaquery(md) {
	  max-width: config.$max_width_content_md;
	}
  }
  @if(config.$max_width_content_lg != config.$max_width_content_md) {
	@include config.mediaquery(lg) {
	  max-width: config.$max_width_content_lg;
	}
  }
}
@mixin set-max-width-inner-content() {
  & {
	max-width: config.$max_width_inner_content_xs;
  }

  @if(config.$max_width_inner_content_sm != config.$max_width_inner_content_xs) {
	@include config.mediaquery(sm) {
	  max-width: config.$max_width_inner_content_sm;
	}
  }
  @if(config.$max_width_inner_content_md != config.$max_width_inner_content_sm) {
	@include config.mediaquery(md) {
	  max-width: config.$max_width_inner_content_md;
	}
  }
  @if(config.$max_width_inner_content_lg != config.$max_width_inner_content_md) {
	@include config.mediaquery(lg) {
	  max-width: config.$max_width_inner_content_lg;
	}
  }
}

// ...
// PADDINGS
@mixin set-inner-padding-sides($multiplier_top: 1, $multiplier_right: 1, $multiplier_bottom: 1, $multiplier_left: 1) {

  & {
	padding: (config.$inner_padding_xs * $multiplier_top) (config.$inner_padding_xs * $multiplier_right) (config.$inner_padding_xs * $multiplier_bottom) (config.$inner_padding_xs * $multiplier_left);
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  padding: (config.$inner_padding_sm * $multiplier_top) (config.$inner_padding_sm * $multiplier_right) (config.$inner_padding_sm * $multiplier_bottom) (config.$inner_padding_sm * $multiplier_left);
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  padding: (config.$inner_padding_md * $multiplier_top) (config.$inner_padding_md * $multiplier_right) (config.$inner_padding_md * $multiplier_bottom) (config.$inner_padding_md * $multiplier_left);
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  padding: (config.$inner_padding_lg * $multiplier_top) (config.$inner_padding_lg * $multiplier_right) (config.$inner_padding_lg * $multiplier_bottom) (config.$inner_padding_lg * $multiplier_left);
	}
  }
}
@mixin set-inner-padding-top($multiplier: 1) {

  & {
	padding-top: (config.$inner_padding_xs * $multiplier);
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  padding-top: (config.$inner_padding_sm * $multiplier);
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  padding-top: (config.$inner_padding_md * $multiplier);
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  padding-top: (config.$inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-padding-right($multiplier: 1) {

  & {
	padding-right: (config.$inner_padding_xs * $multiplier);
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  padding-right: (config.$inner_padding_sm * $multiplier);
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  padding-right: (config.$inner_padding_md * $multiplier);
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  padding-right: (config.$inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-padding-bottom($multiplier: 1) {

  & {
	padding-bottom: (config.$inner_padding_xs * $multiplier);
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  padding-bottom: (config.$inner_padding_sm * $multiplier);
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  padding-bottom: (config.$inner_padding_md * $multiplier);
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  padding-bottom: (config.$inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-padding-left($multiplier: 1) {

  & {
	padding-left: (config.$inner_padding_xs * $multiplier);
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  padding-left: (config.$inner_padding_sm * $multiplier);
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  padding-left: (config.$inner_padding_md * $multiplier);
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  padding-left: (config.$inner_padding_lg * $multiplier);
	}
  }
}

// ...
// MARGINS
@mixin set-inner-margin-sides($multiplier_top: 1, $multiplier_right: 1, $multiplier_bottom: 1, $multiplier_left: 1) {

  & {
	margin: (config.$inner_padding_xs * $multiplier_top) (config.$inner_padding_xs * $multiplier_right) (config.$inner_padding_xs * $multiplier_bottom) (config.$inner_padding_xs * $multiplier_left);
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  margin: (config.$inner_padding_sm * $multiplier_top) (config.$inner_padding_sm * $multiplier_right) (config.$inner_padding_sm * $multiplier_bottom) (config.$inner_padding_sm * $multiplier_left);
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  margin: (config.$inner_padding_md * $multiplier_top) (config.$inner_padding_md * $multiplier_right) (config.$inner_padding_md * $multiplier_bottom) (config.$inner_padding_md * $multiplier_left);
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  margin: (config.$inner_padding_lg * $multiplier_top) (config.$inner_padding_lg * $multiplier_right) (config.$inner_padding_lg * $multiplier_bottom) (config.$inner_padding_lg * $multiplier_left);
	}
  }
}
@mixin set-inner-margin-top($multiplier: 1) {

  & {
	margin-top: (config.$inner_padding_xs * $multiplier);
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  margin-top: (config.$inner_padding_sm * $multiplier);
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  margin-top: (config.$inner_padding_md * $multiplier);
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  margin-top: (config.$inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-margin-right($multiplier: 1) {

  & {
	margin-right: (config.$inner_padding_xs * $multiplier);
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  margin-right: (config.$inner_padding_sm * $multiplier);
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  margin-right: (config.$inner_padding_md * $multiplier);
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  margin-right: (config.$inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-margin-bottom($multiplier: 1) {

  & {
	margin-bottom: (config.$inner_padding_xs * $multiplier);
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  margin-bottom: (config.$inner_padding_sm * $multiplier);
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  margin-bottom: (config.$inner_padding_md * $multiplier);
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  margin-bottom: (config.$inner_padding_lg * $multiplier);
	}
  }
}
@mixin set-inner-margin-left($multiplier: 1) {

  & {
	margin-left: (config.$inner_padding_xs * $multiplier);
  }

  @if(config.$inner_padding_sm != config.$inner_padding_xs) {
	@include config.mediaquery(sm) {
	  margin-left: (config.$inner_padding_sm * $multiplier);
	}
  }
  @if(config.$inner_padding_md != config.$inner_padding_sm) {
	@include config.mediaquery(md) {
	  margin-left: (config.$inner_padding_md * $multiplier);
	}
  }
  @if(config.$inner_padding_lg != config.$inner_padding_md) {
	@include config.mediaquery(lg) {
	  margin-left: (config.$inner_padding_lg * $multiplier);
	}
  }
}

// ...
// HEADER HEIGHTS
@mixin set-header-heights() {

  & {
	height: config.$height_header_xs;
  }

  @if(config.$height_header_sm != config.$height_header_xs) {
	@include config.mediaquery(sm) {
	  height: config.$height_header_sm;
	}
  }
  @if(config.$height_header_md != config.$height_header_sm) {
	@include config.mediaquery(md) {
	  height: config.$height_header_md;
	}
  }
  @if(config.$height_header_lg != config.$height_header_md) {
	@include config.mediaquery(lg) {
	  height: config.$height_header_lg;
	}
  }
}
@mixin set-padding-top-from-header-heights() {

  & {
	padding-top: config.$height_header_xs;
  }

  @if(config.$height_header_sm != config.$height_header_xs) {
	@include config.mediaquery(sm) {
	  padding-top: config.$height_header_sm;
	}
  }
  @if(config.$height_header_md != config.$height_header_sm) {
	@include config.mediaquery(md) {
	  padding-top: config.$height_header_md;
	}
  }
  @if(config.$height_header_lg != config.$height_header_md) {
	@include config.mediaquery(lg) {
	  padding-top: config.$height_header_lg;
	}
  }
}
@mixin set-padding-bottom-from-header-heights() {

  & {
	padding-bottom: config.$height_header_xs;
  }

  @if(config.$height_header_sm != config.$height_header_xs) {
	@include config.mediaquery(sm) {
	  padding-bottom: config.$height_header_sm;
	}
  }
  @if(config.$height_header_md != config.$height_header_sm) {
	@include config.mediaquery(md) {
	  padding-bottom: config.$height_header_md;
	}
  }
  @if(config.$height_header_lg != config.$height_header_md) {
	@include config.mediaquery(lg) {
	  padding-bottom: config.$height_header_lg;
	}
  }
}
@mixin set-scroll-margin-top-from-header-heights() {

  & {
	scroll-margin-top: config.$height_header_xs;
  }

  @if(config.$height_header_sm != config.$height_header_xs) {
	@include config.mediaquery(sm) {
	  scroll-margin-top: config.$height_header_sm;
	}
  }
  @if(config.$height_header_md != config.$height_header_sm) {
	@include config.mediaquery(md) {
	  scroll-margin-top: config.$height_header_md;
	}
  }
  @if(config.$height_header_lg != config.$height_header_md) {
	@include config.mediaquery(lg) {
	  scroll-margin-top: config.$height_header_lg;
	}
  }
}

@mixin set-margin-top-from-header-heights() {

  & {
	margin-top: config.$height_header_xs;
  }

  @if(config.$height_header_sm != config.$height_header_xs) {
	@include config.mediaquery(sm) {
	  margin-top: config.$height_header_sm;
	}
  }
  @if(config.$height_header_md != config.$height_header_sm) {
	@include config.mediaquery(md) {
	  margin-top: config.$height_header_md;
	}
  }
  @if(config.$height_header_lg != config.$height_header_md) {
	@include config.mediaquery(lg) {
	  margin-top: config.$height_header_lg;
	}
  }
}
@mixin set-negative-margin-top-from-header-heights() {

  & {
	margin-top: -#{config.$height_header_xs};
  }

  @if(config.$height_header_sm != config.$height_header_xs) {
	@include config.mediaquery(sm) {
	  margin-top: -#{config.$height_header_sm};
	}
  }
  @if(config.$height_header_md != config.$height_header_sm) {
	@include config.mediaquery(md) {
	  margin-top: -#{config.$height_header_md};
	}
  }
  @if(config.$height_header_lg != config.$height_header_md) {
	@include config.mediaquery(lg) {
	  margin-top: -#{config.$height_header_lg};
	}
  }
}

@mixin set-top-from-header-heights() {

  & {
	top: config.$height_header_xs;
  }

  @if(config.$height_header_sm != config.$height_header_xs) {
	@include config.mediaquery(sm) {
	  top: config.$height_header_sm;
	}
  }
  @if(config.$height_header_md != config.$height_header_sm) {
	@include config.mediaquery(md) {
	  top: config.$height_header_md;
	}
  }
  @if(config.$height_header_lg != config.$height_header_md) {
	@include config.mediaquery(lg) {
	  top: config.$height_header_lg;
	}
  }
}

@mixin set-app-height-from-header-heights($extraHeight:0) {

  @if($extraHeight != 0) {
  	& {
	  height: calc(var(--app-height) - #{config.$height_header_xs} + #{$extraHeight});
	}
  }
  @else {
	& {
	  height: calc(var(--app-height) - #{config.$height_header_xs});
	}
  }

  @if(config.$height_header_sm != config.$height_header_xs) {
	@include config.mediaquery(sm) {
	  @if($extraHeight != 0) {
		height: calc(var(--app-height) - #{config.$height_header_sm} + #{$extraHeight});
	  }
	  @else {
		height: calc(var(--app-height) - #{config.$height_header_sm});
	  }
	}
  }
  @if(config.$height_header_md != config.$height_header_sm) {
	@include config.mediaquery(md) {
	  @if($extraHeight != 0) {
		height: calc(var(--app-height) - #{config.$height_header_md} + #{$extraHeight});
	  }
	  @else {
		height: calc(var(--app-height) - #{config.$height_header_md});
	  }
	}
  }
  @if(config.$height_header_lg != config.$height_header_md) {
	@include config.mediaquery(lg) {
	  @if($extraHeight != 0) {
		height: calc(var(--app-height) - #{config.$height_header_lg} + #{$extraHeight});
	  }
	  @else {
		height: calc(var(--app-height) - #{config.$height_header_lg});
	  }
	}
  }
}

@mixin set-app-max-height-from-header-heights($extraHeight:0) {

  @if($extraHeight != 0) {
	& {
	  max-height: calc(var(--app-height) - #{config.$height_header_xs} + #{$extraHeight});
	}
  }
  @else {
	& {
	  max-height: calc(var(--app-height) - #{config.$height_header_xs});
	}
  }

  @if(config.$height_header_sm != config.$height_header_xs) {
	@include config.mediaquery(sm) {
	  @if($extraHeight != 0) {
		max-height: calc(var(--app-height) - #{config.$height_header_sm} + #{$extraHeight});
	  }
	  @else {
		max-height: calc(var(--app-height) - #{config.$height_header_sm});
	  }
	}
  }
  @if(config.$height_header_md != config.$height_header_sm) {
	@include config.mediaquery(md) {
	  @if($extraHeight != 0) {
		max-height: calc(var(--app-height) - #{config.$height_header_md} + #{$extraHeight});
	  }
	  @else {
		max-height: calc(var(--app-height) - #{config.$height_header_md});
	  }
	}
  }
  @if(config.$height_header_lg != config.$height_header_md) {
	@include config.mediaquery(lg) {
	  @if($extraHeight != 0) {
		max-height: calc(var(--app-height) - #{config.$height_header_lg} + #{$extraHeight});
	  }
	  @else {
		max-height: calc(var(--app-height) - #{config.$height_header_lg});
	  }
	}
  }
}

// ...
// TITLE BAR HEIGHTS
@mixin set-title_bar-heights() {

  & {
	height: config.$height_title_bar_xs;
  }

  @if(config.$height_title_bar_sm != config.$height_title_bar_xs) {
	@include config.mediaquery(sm) {
	  height: config.$height_title_bar_sm;
	}
  }
  @if(config.$height_title_bar_md != config.$height_title_bar_sm) {
	@include config.mediaquery(md) {
	  height: config.$height_title_bar_md;
	}
  }
  @if(config.$height_title_bar_lg != config.$height_title_bar_md) {
	@include config.mediaquery(lg) {
	  height: config.$height_title_bar_lg;
	}
  }
}
@mixin set-margin-top-from-title_bar-heights() {

  & {
	margin-top: config.$height_title_bar_xs;
  }

  @if(config.$height_title_bar_sm != config.$height_title_bar_xs) {
	@include config.mediaquery(sm) {
	  margin-top: config.$height_title_bar_sm;
	}
  }
  @if(config.$height_title_bar_md != config.$height_title_bar_sm) {
	@include config.mediaquery(md) {
	  margin-top: config.$height_title_bar_md;
	}
  }
  @if(config.$height_title_bar_lg != config.$height_title_bar_md) {
	@include config.mediaquery(lg) {
	  margin-top: config.$height_title_bar_lg;
	}
  }
}
@mixin set-padding-top-from-title_bar-heights() {

  & {
	padding-top: config.$height_title_bar_xs;
  }

  @if(config.$height_title_bar_sm != config.$height_title_bar_xs) {
	@include config.mediaquery(sm) {
	  padding-top: config.$height_title_bar_sm;
	}
  }
  @if(config.$height_title_bar_md != config.$height_title_bar_sm) {
	@include config.mediaquery(md) {
	  padding-top: config.$height_title_bar_md;
	}
  }
  @if(config.$height_title_bar_lg != config.$height_title_bar_md) {
	@include config.mediaquery(lg) {
	  padding-top: config.$height_title_bar_lg;
	}
  }
}