// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.wp-block-columns
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5em 2em;
  margin-bottom: 1.5em;
  width: 100%;

  @include config.mediaquery(sm)
  {
	gap: 2em 2em;
	flex-wrap: nowrap;
  }

  .wp-block-column.has-background
  {
	padding: 2em;
  }

  .wp-block-column
  {
	@include config.mediaquery(xs2sm)
	{
	  flex-basis: 100% !important;
	  width: 100% !important;
	}
	@include config.mediaquery(sm)
	{
	  flex-grow: 1;
	  flex-basis: 50%;
	  width: 50%;
	}
  }

  p
  {
	&:last-child
	{
	  margin-bottom: 0;
	}
  }
}