// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.wp-block-file
{
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-top: 1em;
  margin-bottom: 1.5em;

  //@include block-margin-top(1, true);
  //@include block-margin-bottom(2, true);

  &:last-child
  {
	margin-bottom: 0;
  }

  a:not(.wp-block-file__button):not(:last-child)
  {
	display: none;
  }
  a:not(.wp-block-file__button):last-child,
  a.wp-block-file__button
  {
	@include form.reset-default-button-style;

	&
	{
	  display: inline-flex;
	  flex-wrap: nowrap;
	  justify-content: flex-start;
	  align-items: flex-end;

	  color: color.get-color(1);
	  word-break: break-word;
	  text-decoration: none;
	  //text-decoration: underline;
	  //text-decoration-thickness: 0.1rem;
	  //text-underline-position: under;

	  //@include typography.typography-1-bold();
	  //@include set-normal-text-sizes();
	}

	&:before
	{
	  flex-grow: 0;
	  flex-shrink: 0;

	  content: '';
	  display: block;
	  background: transparent url("#{config.$path_images}file-icons/default.svg") no-repeat 50% 50%;
	  background-size: cover;
	  margin-right: .5em;
	  width: 4rem;
	  height: 4rem;
	}

	/*
	&:hover,
	&:active,
	&:focus,
	&:visited
	{
	  opacity: 1 !important;
	  color: color.get-color(10);
	  text-decoration: underline;
	  text-decoration-thickness: 0.1rem;
	}

	 */
  }

  //
  // Other file types
  &.file-type-pdf a:before { background-image: url("#{config.$path_images}file-icons/pdf.svg") !important; }
  //&.file-type-spreadsheet a:before { background-image: url("#{$_path_images}file-icons/spreadsheet.svg") !important; }
  //&.file-type-text a:before { background-image: url("#{$_path_images}file-icons/text.svg") !important; }

  //
  // Adjacent
  & + .wp-block-file
  {
	margin-top: -.5em;
  }
}