// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.acf-block-slideshow
{
  margin-top: 2.5em;
  margin-bottom: 3em;

  .acf-block-slideshow-inner
  {
	position: relative;
	@include layout.aspect-ratio(16, 9);
  }

  .splide
  {
	position: absolute;
	inset: 0 0 0 0;
	width: 100%;
	height: 100%;
  }
  .splide__track
  {
	height: 100%;
  }
  .splide__slide
  {
	//position: relative;
	//height: 100%;
	img
	{
	  display: block;
	  position: absolute;
	  inset: 0 0 0 0;
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}
  }

  .splide__arrows
  {
	position: absolute;
	z-index: 333;
	width: 100%;
	height: 100%;
	pointer-events: none;

	.splide__arrow
	{
	  @include form.reset-default-button-style();
	  &
	  {
		position: absolute;
		top: 50%;
		right: 1em;
		z-index: 222;

		background: url("#{config.$path_images}swiper-arrow.svg") no-repeat 50% 50%;
		background-size: cover;
		margin-top: -1.1rem;
		width: 1.2rem;
		height: 2.3rem;
		cursor: pointer;
		transition: opacity .25s ease-in-out;
		pointer-events: auto;
	  }

	  @include config.mediaquery(sm)
	  {
		right: 1.5em;
		margin-top: -2.3rem;
		width: 2.4rem;
		height: 4.6rem;
	  }

	  &[disabled]
	  {
		opacity: .5;
		cursor: not-allowed;
	  }
	  &.splide__arrow--prev
	  {
		right: auto;
		left: 1em;
		transform: translate3d(0,0,0) scaleX(-1);
		@include config.mediaquery(sm)
		{
		  left: 1.5em;
		}
	  }
	}
  }

  &.full-width
  {
	background: transparent !important;

	@include layout.make-fullscreen();

	.acf-block-slideshow-inner
	{
	  @include layout.container-style('large', true);
	  & {
		margin: 0 auto;
	  }
	}
  }
}