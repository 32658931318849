// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.blog-7,
.blogpath-gymnastik
{
  .page-layout:after, // Titlebar "fake"
  #title-bar,
  #menu-blog
  {
	background: color.get-color(70);
  }
  #child-pages ul .child-page .index,
  .title-area .title-area-inner .index
  {
	background: color.get-color(70);
  }

  #menu-overlay:before
  {
	background: color.get-color(70);
  }

  .loading-animation
  {
	.spinner
	{
	  border-color: rgba(color.get-color(70, false), .3);
	  border-left-color: color.get-color(70);
	}
  }
  .the-content ul,
  .the-content p
  {
	a
	{
	  color: color.get-color(70);
	}
  }

  #the-header
  {
	// Change the Antibulli logo
	.logo
	{
	  @include layout.aspect-ratio(824,172);
	  &
	  {
		background: url("#{config.$path_images}blogs/7/logo-GymDK.svg") no-repeat 50% 50%;
		background-size: cover;
		width: 25rem;
	  }

	  .name
	  {
		display: none;
	  }
	}

	.menu-primary .menu .menu-item
	{
	  &.icon-gymnastics
	  {
		a
		{
		  .icon
		  {
			width: 3.8rem;
			height: 2.2rem;
		  }
		  .title
		  {
			display: block;
			margin-top: .2rem;
		  }
		}
	  }
	  &.icon-gymnastics.js-active
	  {
		a
		{
		  .icon
		  {
			svg
			{
			  path
			  {
				fill: color.get-color(3);
			  }
			}
		  }
		}
	  }

	  &.icon-gymnastics-mat
	  {
		a
		{
		  .icon
		  {
			position: relative;
			top: -.1rem;
			width: 3rem;
			height: 3rem;
		  }
		  .title
		  {
			display: block;
			margin-top: -.6rem;
		  }
		}
	  }
	  &.icon-gymnastics-mat.js-active
	  {
		a
		{
		  .icon
		  {
			svg
			{
			  path
			  {
				stroke: color.get-color(3);
				fill: none;
			  }
			}
		  }
		}
	  }
	  &.icon-gymnastics-rings
	  {
		.icon
		{
		  width: 3.2rem;

		  svg
		  {
			position: relative;
			top: -.3rem;
		  }
		}
	  }
	  &.icon-gymnastics-rings.js-active
	  {
		.icon
		{
		  svg
		  {
			path
			{
			  fill: none !important;
			  stroke: none !important;

			  &.ring-outline
			  {
				fill: color.get-color(3) !important;
			  }
			}
		  }
		}
	  }
	  &.icon-gymnastics-hands
	  {
		.icon
		{
		  width: 3rem;

		  svg
		  {
			position: relative;
			top: -.1rem;
		  }
		}
	  }
	  &.icon-gymnastics-hands.js-active
	  {
		.icon
		{
		  svg
		  {
			path
			{
			  fill: none !important;
			  stroke: color.get-color(3) !important;
			}
		  }
		}
	  }
	}
  }

  @include config.mediaquery(xs2sm)
  {
	#the-header
	{
	  background: color.get-color(70);
	  border-color: color.get-color(70);

	  .menu-primary .menu .menu-item
	  {
		a
		{
		  color: color.get-color(2);
		}

		.icon svg
		{
		  path
		  {
			fill: color.get-color(2);
		  }
		}

		&.js-active
		{
		  a
		  {
			color: color.get-color(1);
		  }

		  .icon svg
		  {
			path
			{
			  fill: color.get-color(1);
			}
		  }
		}

		&.icon-gymnastics
		{
		  a
		  {
			.icon
			{
			  position: relative;
			  top: .2rem;
			}
		  }
		}
		&.icon-gymnastics.js-active
		{
		  a
		  {
			.icon
			{
			  svg
			  {
				path
				{
				  fill: color.get-color(1);
				}
			  }
			}
		  }
		}

		&.icon-gymnastics-mat
		{
		  .icon
		  {
			top: -.2rem;
			svg
			{
			  path
			  {
				fill: none;
				stroke: color.get-color(2);
			  }
			}
		  }
		  .title
		  {
			margin-top: -.8rem;
		  }
		}
		&.js-active.icon-gymnastics-mat
		{
		  .icon
		  {
			svg
			{
			  path
			  {
				stroke: color.get-color(1) !important;
			  }
			}
		  }
		  .title
		  {
			color: color.get-color(1);
		  }
		}
		&.icon-gymnastics-rings
		{
		  .icon
		  {
			svg
			{
			  path
			  {
				fill: none !important;
				stroke: none !important;

				&.ring-outline
				{
				  fill: color.get-color(2) !important;
				}
			  }
			}
		  }
		}
		&.icon-gymnastics-rings.js-active
		{
		  .icon
		  {
			svg
			{
			  path
			  {
				&.ring-outline
				{
				  fill: color.get-color(1) !important;
				}
			  }
			}
		  }
		  .title
		  {
			color: color.get-color(1);
		  }
		}
		&.icon-gymnastics-hands
		{
		  .icon
		  {
			svg
			{
			  path
			  {
				fill: transparent !important;
				stroke: color.get-color(2) !important;
			  }
			}
		  }
		}
		&.icon-gymnastics-hands.js-active
		{
		  .icon
		  {
			svg
			{
			  path
			  {
				fill: transparent !important;
				stroke: color.get-color(1) !important;
			  }
			}
		  }
		  .title
		  {
			color: color.get-color(1);
		  }
		}
	  }
	}
  }
}
/*body[data-template=page_frontpage_sport][data-blog="7"] .the-editor-content,
body[data-template=page_frontpage_sport][data-blog="7"] .page-layout .page-layout-inner
{
  background: color.get-color(71);
}*/

// Set white log on the title-bar (frontpage on ly)
@include config.mediaquery(xs2sm)
{
  body[data-template="page_frontpage_sport"][data-blog="7"],
  body[data-template="page_exercises"][data-blog="7"],
  body[data-template="page_activities"][data-blog="7"],
  body[data-template="page_coaching"][data-blog="7"]
  {
	#title-bar
	{
	  .title-bar-inner
	  {
		justify-content: flex-start;

		.title
		{
		  @include layout.aspect-ratio(824, 172);
		  &
		  {
			background: url("#{config.$path_images}blogs/7/logo-GymDK-White.svg") no-repeat 50% 50%;
			background-size: cover;
			margin: 0;
			width: 14rem;

			opacity: 0;
			animation: antibulli-7-reveal-logo .2s ease-out .2s 1 forwards;
		  }

		  span
		  {
			display: none;
		  }
		}
	  }
	}
  }
}
@keyframes antibulli-7-reveal-logo {
  0% { opacity: 0; }
  100% { opacity: 1; }
}