// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.js-menu-overlay-revealed
{
  //
  // Toggler
  .menu-overlay-toggler
  {
	.line
	{
	  &:nth-child(1)
	  {
		transform: translate3d(0, 0, 0) rotate(45deg);
	  }
	  &:nth-child(2)
	  {
		transform: translate3d(0, 0, 0) rotate(-45deg);
	  }
	}
  }
}