// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.intro
{
  position: relative;
  background: color.get-color(1);
  @include layout.aspect-ratio(16,9);
  & {
	animation: antibulli19-reveal-animation .3s ease-in-out 1 forwards;
  }

  // If one slide has an animation, the make all square on mobile...
  &.has-animation-slide
  {
	@include config.mediaquery(xs2sm)
	{
	  @include layout.aspect-ratio(1,1);
	}
  }

  .context
  {
	position: absolute;
	z-index: 222;
	right: .25em;
	bottom: .25em;
	background: rgba(0,0,0,.6);
	color: color.get-color(2);
	padding: .5em 1em;
	text-align: right;
	pointer-events: none;
	@include config.small-font-size();
	@include typography.typography-1-medium();

	.title
	{
	  color: color.get-color(2);
	  @include typography.typography-1-bold();
	  @include config.normal-font-size();
	}
  }

  .swiper
  {
	position: absolute;
	inset: 0 0 0 0;
	z-index: 111;
	width: 100%;
	height: 100%;

	.swiper-button-prev,
	.swiper-button-next
	{
	  position: absolute;
	  top: 50%;
	  right: 1em;
	  z-index: 222;

	  background: url("#{config.$path_images}swiper-arrow.svg") no-repeat 50% 50%;
	  background-size: cover;
	  margin-top: -1.1rem;
	  width: 1.2rem;
	  height: 2.3rem;
	  cursor: pointer;
	  transition: opacity .25s ease-in-out;

	  @include config.mediaquery(sm)
	  {
		right: 1.5em;
		margin-top: -2.3rem;
		width: 2.4rem;
		height: 4.6rem;
	  }

	  &.swiper-button-disabled
	  {
		opacity: .5;
		cursor: not-allowed;
	  }
	}
	.swiper-button-prev
	{
	  right: auto;
	  left: 1em;
	  transform: translate3d(0,0,0) scaleX(-1);
	  @include config.mediaquery(sm)
	  {
		left: 1.5em;
	  }
	}

	.swiper-pagination
	{
	  display: flex;
	  flex-wrap: nowrap;
	  justify-content: center;
	  align-items: center;

	  position: absolute;
	  left: 0;
	  bottom: .5em;
	  z-index: 444;
	  width: 100%;

	  .swiper-pagination-bullet
	  {
		display: block;
		background: color.get-color(2);
		border-radius: 50%;
		margin: 0 .3rem;
		width: .6rem;
		height: .6rem;
		opacity: .5;
		transition: opacity .25s ease-in-out;

		@include config.mediaquery(sm)
		{
		  width: 1rem;
		  height: 1rem;
		}

		&.swiper-pagination-bullet-active
		{
		  opacity: 1;
		}
	  }
	}
  }

  .animation-slide
  {
	.animation
	{
	  width: auto;
	  height: 100%;
	}
  }

  .image-slide
  {
	.image
	{
	  img,
	  .permalink
	  {
		display: block;
		position: absolute;
		inset: 0 0 0 0;
		z-index: 222;
		width: 100%;
		height: 100%;
	  }
	  img
	  {
		z-index: 111;
		object-fit: cover;
	  }
	}
  }

  &.video-playing
  {
	.swiper-pagination
	{
	  display: none;
	}
  }

  &.num-items-1
  {
	.swiper-pagination,
	.swiper-button-prev,
	.swiper-button-next
	{
	  display: none;
	}
  }
}