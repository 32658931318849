// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.cookie-information-template
{
  @include layout.container-style('small');
  &
  {
	margin: 0 auto;
	padding-bottom: 4em;
  }
}