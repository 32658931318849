@use 'sass:math';
@use 'sass:list';

// ...
// Configuration + Mixins
@use "../Config" as config;

//@use "../Mixins/Color" as color;
//@use "../Mixins/Form" as form;
//@use "../Mixins/Layout" as layout;
//@use "../Mixins/Typography" as typography;

@mixin typography-1-light($includeFamily: true, $includeStyle: false) { // set $includeFamily to true as default value if local web fonts
  &
  {
	@if ($includeFamily)
	{
	  font-family: config.$font_family_1__Light;
	}
	@if ($includeStyle)
	{
	  font-style: normal;
	  font-weight: normal;
	}
  }
}
@mixin typography-1-medium($includeFamily: true, $includeStyle: false) { // set $includeFamily to true as default value if local web fonts
  &
  {
	@if ($includeFamily)
	{
	  font-family: config.$font_family_1__Medium;
	}
	@if ($includeStyle)
	{
	  font-style: normal;
	  font-weight: normal;
	}
  }
}
@mixin typography-1-bold($includeFamily: true, $includeStyle: false) { // set $includeFamily to true as default value if local web fonts
  &
  {
	@if ($includeFamily)
	{
	  font-family: config.$font_family_1__Bold;
	}
	@if ($includeStyle)
	{
	  font-style: normal;
	  font-weight: normal;
	}
  }
}


@mixin font-face($name, $file, $weight: normal, $style: normal, $display: swap){
  @font-face {
	font-family: "#{$name}";
	src:url('#{config.$path_fonts}#{$file}.woff2') format('woff2'),
	url('#{config.$path_fonts}#{$file}.woff') format('woff');
	font-weight: $weight;
	font-style: $style;
	font-display: $display;
  }
}

@mixin text-rendering() {
  &
  {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	text-size-adjust: 100%;
  }
}

@mixin text-size($fs, $lh, $important: false) {
  &
  {
	@include line-height($lh, $important);
	@include font-size($fs, $important);
  }
}

@mixin line-height($lh, $important: false) {
  @if list.index('rem' 'em' 'px' '%', math.unit($lh)) != null {
	@if($important) {
	  & {
		line-height: $lh !important;
	  }
	}
	@else {
	  & {
		line-height: $lh;
	  }
	}
  }
  @else { // convert to rem
	@if($important) {
	  & {
		line-height: math.div($lh, 10) + rem !important;
	  }
	}
	@else {
	  & {
		line-height: math.div($lh, 10) + rem;
	  }
	}
  }
}

@mixin font-size($fs, $important: false) {
  @if list.index('rem' 'em' 'px' '%', math.unit($fs)) != null {
	@if($important) {
	  & {
		font-size: $fs !important;
	  }
	}
	@else {
	  & {
		font-size: $fs;
	  }
	}
  }
  @else { // convert to rem
	@if($important) {
	  & {
		font-size: math.div($fs, 10) + rem !important;
	  }
	}
	@else {
	  & {
		font-size: math.div($fs, 10) + rem;
	  }
	}
  }
}