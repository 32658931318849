// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.pdf-download
{
  display: flex;
  justify-content: flex-end;

  position: relative;
  z-index: 444;

  @include layout.container-style('small');
  & {
	margin: 1em auto -2em;
  }

  @include config.mediaquery(sm)
  {
	margin: 1.5em auto -1.5em;
  }

  a
  {
	display: inline-flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	color: color.get-color(1);
	text-decoration: none;
	@include config.normal-font-size();

	&:after
	{
	  flex-grow: 0;
	  flex-shrink: 0;

	  content: '';
	  display: block;
	  background: url("#{config.$path_images}pdf-download.svg") no-repeat 50% 50%;
	  background-size: cover;
	  margin-left: .25em;
	  width: 2.4rem;
	  height: 2.4rem;

	  @include config.mediaquery(sm)
	  {
		width: 4rem;
		height: 4rem;
	  }
	}

	.label
	{
	  display: block;
	  position: relative;
	  top: .125em;
	  white-space: nowrap;
	  line-height: 1em;
	  @include config.small-font-size();
	  @include config.mediaquery(sm)
	  {
		@include config.normal-font-size();
	  }
	}
  }
}