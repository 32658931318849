// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.wp-block-table
{
  margin-top: 2em;
  margin-bottom: 2em;

  &:last-child
  {
	margin-bottom: 0;
  }

  table
  {
	width: 100%;
	border-color: yellow;
  }

  thead
  {
	tr
	{
	  th
	  {
		border: .1rem solid color.get-color(1);
		@include config.h5;
		@include typography.typography-1-medium();
		&
		{
		  margin: 0;
		  padding: 1em 2rem .9em;
		}
	  }
	}
  }
  tbody
  {
	tr
	{
	  td
	  {
		border: .1rem solid color.get-color(1);
		padding: .5em 2rem;
		@include typography.typography-1-light();
	  }
	}
  }
  tfoot
  {
	tr
	{
	  td
	  {
		border: .1rem solid color.get-color(1);
		@include config.h6;
		@include typography.typography-1-medium();
		&
		{
		  margin: 0;
		  padding: .75em 2rem .6em;
		}
	  }
	}
  }
  &.is-style-stripes
  {
	tbody
	{
	  tr
	  {
		&:nth-child(odd)
		{
		  border-color: transparent;
		  background: color.get-color(7);
		}
	  }
	}
  }
}