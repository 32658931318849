// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.animation
{
  position: relative;
  background: color.get-color(0);
  //@include layout.aspect-ratio(750, 510);
  @include layout.aspect-ratio(1, 1);
  & {
	animation: antibulli19-reveal-animation .3s ease-in-out 1 forwards;
  }

  @include config.mediaquery(sm) {
	@include layout.aspect-ratio(16,9);
  }

  video,
  .image,
  .image img
  {
	position: absolute;
	inset: 0 0 0 0;
	z-index: 111;
	width: 100%;
	height: 100%;
	object-fit: contain;
  }
  video
  {
	animation: antibulli19-reveal-animation .3s ease-in-out 1 forwards;
  }

  .endframe
  {
	visibility: hidden;
	z-index: 444;
  }
  .poster
  {
	visibility: hidden;
	z-index: 333;
  }

  &.state-idle
  {
	.poster
	{
	  visibility: visible;
	}
	&.has-poster
	{
	  video
	  {
		visibility: hidden !important;
	  }
	}
  }
  &.state-ended
  {
	.endframe,
	.poster
	{
	  visibility: visible;
	}
	&.has-poster
	{
	  video
	  {
		visibility: hidden !important;
	  }
	}
  }

  @include config.mediaquery(xs2sm)
  {
	.fullscreen-button
	{
	  display: none;
	}
  }
}