// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.scroll-arrow
{
  @include form.reset-default-button-style;
  @include form.no-appearance;
  &
  {
	display: flex;
	justify-content: center;
	align-items: center;

	position: fixed;
	top: var(--app-height);
	left: 50%;
	z-index: 666;
	background: color.get-color(2);
	border-radius: 50%;
	margin: -5rem 0 0 -1.5rem;
	width: 3rem;
	height: 3rem;
	transition: opacity .15s ease-out;
	animation: antibulli19-scroll-arrow .65s ease-in-out infinite alternate;
  }
  svg
  {
	position: relative;
	top: .1rem;
	display: block;
	width: 60%;
	height: auto;
  }

  &.is-hidden
  {
	opacity: 0;
	pointer-events: none;
  }

  @include config.mediaquery(sm)
  {
	display: none;
  }

  @keyframes antibulli19-scroll-arrow {
	0% { transform: translate3d(0,-1em,0); }
	100% { transform: translate3d(0,0,0); }
  }

}