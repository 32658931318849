@use 'sass:map';

// ...
// Configuration + Mixins
@use "../Config" as config;

//@use "../Mixins/Color" as color;
//@use "../Mixins/Form" as form;
//@use "../Mixins/Layout" as layout;
//@use "../Mixins/Typography" as typography;

@mixin set-root-colors() {
  @each $name, $color in config.$colors
  {
	--color-#{$name}: #{$color};
  }
}
@function get-color($color, $useRoot: config.$useRootColors) {
  @if($useRoot) {
	@return var(--color-#{$color});
  }
  @return map.get(config.$colors, $color);
}


@mixin set-color($color, $important: false, $useRoot: config.$useRootColors) {
  @if($important) {
	& {
	  color: get-color($color, $useRoot) !important;
	}
  }
  @else {
	& {
	  color: get-color($color, $useRoot);
	}
  }
}

@mixin set-background-color($color, $important: false, $useRoot: config.$useRootColors) {
  @if($important) {
	& {
	  background-color: get-color($color, $useRoot) !important;
	}
  }
  @else {
	& {
	  background-color: get-color($color, $useRoot);
	}
  }
}

@mixin set-border-color($color, $important: false, $useRoot: config.$useRootColors) {
  @if($important) {
	& {
	  border-color: get-color($color, $useRoot) !important;
	}
  }
  @else {
	& {
	  border-color: get-color($color, $useRoot);
	}
  }
}

//
// Gutenberg...
@mixin set-gutenberg-colors($important: false, $_useRootColors:true) {

  @each $name, $color in config.$colors
  {

	// Background
	&.has-color-#{$name}-background-color,
	.has-color-#{$name}-background-color {
	  @if($_useRootColors) {

		@if($important) {
		  background-color: var(--color-#{$name}) !important;
		}
		@else {
		  background-color: var(--color-#{$name});
		}
	  }
	  @else {

		@if($important) {
		  background-color: $color !important;
		}
		@else {
		  background-color: $color;
		}
	  }
	}

	// Foreground
	&.has-color-#{$name}-color,
	.has-color-#{$name}-color {
	  @if($_useRootColors) {

		@if($important) {
		  color: var(--color-#{$name}) !important;
		}
		@else {
		  color: var(--color-#{$name});
		}
	  }
	  @else {

		@if($important) {
		  color: $color !important;
		}
		@else {
		  color: $color;
		}
	  }
	}
  }
}

@mixin set-gutenberg-border-colors($important: false, $_useRootColors:true) {

  @each $name, $color in config.$colors
  {

	&.has-color-#{$name}-border-color,
	.has-color-#{$name}-border-color {
	  @if($_useRootColors) {

		@if($important) {
		  border-color: var(--color-#{$name}) !important;
		}
		@else {
		  border-color: var(--color-#{$name});
		}
	  }
	  @else {

		@if($important) {
		  border-color: $color !important;
		}
		@else {
		  border-color: $color;
		}
	  }
	}
  }
}

@mixin set-gutenberg-svg-path-fill-colors($important: false, $_useRootColors:true) {

  @each $name, $color in config.$colors
  {

	// Foreground
	&.has-color-#{$name}-color,
	.has-color-#{$name}-color {
	  @if($_useRootColors) {

		@if($important) {
		  svg
		  {
			path
			{
			  fill: var(--color-#{$name}) !important;
			}
		  }
		}
		@else {
		  svg
		  {
			path
			{
			  fill: var(--color-#{$name});
			}
		  }
		}
	  }
	  @else {

		@if($important) {
		  svg
		  {
			path
			{
			  fill: $color !important;
			}
		  }
		}
		@else {
		  svg
		  {
			path
			{
			  fill: $color;
			}
		  }
		}
	  }
	}
  }
}

@mixin set-gutenberg-svg-path-fill-colors-from-background($important: false, $_useRootColors:true) {

  @each $name, $color in config.$colors
  {

	// Foreground
	&.has-color-#{$name}-background-color,
	.has-color-#{$name}-background-color {
	  @if($_useRootColors) {

		@if($important) {
		  svg
		  {
			path
			{
			  fill: var(--color-#{$name}) !important;
			}
		  }
		}
		@else {
		  svg
		  {
			path
			{
			  fill: var(--color-#{$name});
			}
		  }
		}
	  }
	  @else {

		@if($important) {
		  svg
		  {
			path
			{
			  fill: $color !important;
			}
		  }
		}
		@else {
		  svg
		  {
			path
			{
			  fill: $color;
			}
		  }
		}
	  }
	}
  }
}