// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

#title-bar
{
  @include layout.make-fullscreen();
  &
  {
	position: fixed;
	top: env(safe-area-inset-top, 0);
	z-index: 889;
	background: color.get-color(1);
	color: color.get-color(2);
  }

  @include config.mediaquery(sm)
  {
	@include layout.set-top-from-header-heights();// The Header is positioned fixed at the bottom of the page...
  }
  @include config.mediaquery(xs2sm)
  {
	// Cover safe area above
	&:before
	{
	  content: '';
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  background: inherit;
	  width: 100%;
	  height: 200px;
	  pointer-events: none;
	  transform: translateY(-100%);
	}
  }

  .title-bar-inner
  {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;

	@include layout.container-style('large');
	&
	{
	  margin: 0 auto;
	  padding-top: .2rem;
	}
	@include layout.set-title_bar-heights();
  }

  &.no-permalink
  {
	.title-bar-inner
	{
	  justify-content: center;
	  .title
	  {
		display: block;
		text-align: center;
	  }
	}
  }

  .permalink
  {
	display: inline-flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	color: color.get-color(2);
	text-decoration: none;

	@include config.mediaquery(sm)
	{
	  margin-left: 1.5rem;
	}

	svg
	{
	  display: block;
	  margin-right: .5em;
	  width: 1rem;
	  height: auto;

	  path
	  {
		fill: color.get-color(2);
	  }
	}
	span
	{
	  position: relative;
	  top: .1rem;
	  white-space: nowrap;
	  @include config.small-font-size();
	}
  }

  .title
  {
	display: none; // Only showed if no permalink...
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	max-width: 70%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	@include config.mediaquery(sm)
	{
	  display: block;
	}

	span
	{
	  @include config.small-font-size();
	  @include typography.typography-1-bold();
	}
  }
}