// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.vimeo-video
{
  .poster,
  .poster img,
  iframe
  {
	display: block;
	position: absolute;
	inset: 0 0 0 0;
	z-index: 222;
	width: 100%;
	height: 100%;
	object-fit: cover;
  }
  .poster
  {
	z-index: 111;
  }
}