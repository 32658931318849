// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.wp-block-separator
{
  justify-self: flex-start;

  border-top: none; // make it single pixel
  @include layout.set-max-width-container-large(true);

  & {
	margin: 2em auto 2em;
  }

  @include config.mediaquery(xs2sm)
  {
	margin-bottom: 2.5em;

	&:not(.is-style-wide):not(.is-style-dots)
	{
	  width: calc(100% - #{config.$inner_padding_xs * 2}) !important;
	}
  }
  &.is-style-wide
  {
	@include layout.set-max-width-container-large(true);
  }
  &.is-style-dots
  {
	&:before
	{
	  display: block;
	  padding: 0;
	  letter-spacing: .5em;
	  font-size: 3em;
	  @include typography.typography-1-bold();
	}
  }

  &:not(.has-background-color)
  {
	border-color: color.get-color(1);
  }
}