// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.wp-block-group
{
  margin-top: 2em;
  margin-bottom: 2em;
  background: transparent !important;

  @include layout.make-fullscreen();

  .wp-block-group__inner-container
  {
	@include layout.container-style('large', true);
	& {
	  margin: 0 auto;
	}
  }

  &:last-child
  {
	margin-bottom: 0;
	padding-bottom: 0;
  }

  &.has-background
  {
	@each $name, $color in config.$colors
	{

	  // Background
	  &.has-color-#{$name}-background-color,
	  .has-color-#{$name}-background-color {

		.wp-block-group__inner-container
		{
		  @if(config.$useRootColors) {
			  background-color: var(--color-#{$name});
		  }
		  @else {
			  background-color: $color;
		  }
		}
	  }

	  // Foreground
	  &.has-color-#{$name}-color,
	  .has-color-#{$name}-color {

		.wp-block-group__inner-container
		{
		  @if(config.$useRootColors) {
			color: var(--color-#{$name});
		  }
		  @else {
			color: $color;
		  }
		}
	  }
	}

	.wp-block-group__inner-container
	{
	  @include layout.set-inner-padding-sides(1,0,1,0);
	  & > *:not(.wp-block-image)
	  {
		@include layout.set-inner-padding-sides(0,1,0,1);
	  }
	}
  }

  p:last-child
  {
	margin-bottom: 0;
  }

  .wp-block-image,
  .wp-block-post-featured-image
  {
	img
	{
	  width: 100%;
	}
	figcaption
	{
	  @include layout.container-style('small');
	  @include config.mediaquery(lg)
	  {
		@include layout.container-style('small');
	  }
	}
  }

}