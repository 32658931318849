// ...
// Configuration + Mixins
@use "../Config" as config;

@use "../Mixins/Color" as color;
@use "../Mixins/Form" as form;
@use "../Mixins/Layout" as layout;
@use "../Mixins/Typography" as typography;

.blogs
{
  position: relative;
  background: color.get-color(0);
  margin-bottom: 2em;
  opacity: 0;
  animation: antibulli19-reveal-animation .3s ease-in-out .75s 1 forwards;

  ul
  {
	display: flex;
	flex-wrap: wrap;
	//justify-content: center;
	justify-content: flex-start;
	align-items: flex-start;

	gap: 5rem 5.5rem;
	gap: 5rem 3rem;

	@include layout.container-style('small');
	& {
	  margin: 0 auto -1em;
	}
	@include config.mediaquery(xs2xsbig)
	{
	  display: grid;
	  grid-template-columns: repeat(2, minmax(0, 1fr));
	  gap: 2rem 2.5rem;
	  max-width: 28rem;
	}

	li
	{
	  display: flex;
	  flex-wrap: nowrap;
	  justify-content: flex-start;
	  align-items: flex-start;

	  //width: 100%;
	  width: 100%;
	  @include config.mediaquery(xsbig)
	  {
		//width: clamp(10rem, calc(33.333333% - 5rem), 16rem);
		width: clamp(10rem, calc(25% - 3rem), 20rem);
	  }

	  a
	  {
		display: block;
		color: color.get-color(2);
		width: 100%;
		text-decoration: none;
		text-align: center;
		@include typography.typography-1-medium();
		@include config.small-font-size();

		@include config.mediaquery(sm)
		{
		  @include config.normal-font-size();
		}
	  }
	  .icon
	  {
		@include layout.aspect-ratio(1,1);
		&
		{
		  display: block;
		  position: relative;
		  border: .2rem solid color.get-color(2);
		  margin-bottom: .5em;
		  width: 100%;
		}

		img
		{
		  position: absolute;
		  inset: 0 0 0 0;
		  width: 100%;
		  height: 100%;
		  object-fit: cover;
		}
	  }
	}
  }
}